// extracted by mini-css-extract-plugin
export var card = "IdeaToImpact-module--card--8e0f4";
export var cardWrapper = "IdeaToImpact-module--cardWrapper--9aa21";
export var description = "IdeaToImpact-module--description--f0e37";
export var enterpriseBuildBg = "IdeaToImpact-module--enterpriseBuildBg--c4747";
export var fixed = "IdeaToImpact-module--fixed--2a586";
export var headInline = "IdeaToImpact-module--headInline--81dfb";
export var hire = "IdeaToImpact-module--hire--b3df0";
export var hireCyber = "IdeaToImpact-module--hireCyber--e9b4f";
export var imgCol = "IdeaToImpact-module--imgCol--d2e50";
export var leftCol = "IdeaToImpact-module--leftCol--56eca";
export var number = "IdeaToImpact-module--number--a1c23";
export var start = "IdeaToImpact-module--start--ab6e6";
export var subHeading = "IdeaToImpact-module--subHeading--edd63";