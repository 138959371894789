import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./IZ-Contribute.module.scss"
import Icon from "../../images/containerization/users.svg"

const cardData = [
  {
    icon: Icon,
    heading: (
      <>
        <span className={styles.colorBlue}>Effective</span>Communication
      </>
    ),
    description:
      "We provide clear and open dialogue with your teams, eliminating misunderstandings and keeping everyone aligned.",
    textColor: "black",
  },
  {
    icon: Icon,
    heading: (
      <>
        <span className={styles.colorBlue}>Effective</span>Communication
      </>
    ),
    description:
      "We provide clear and open dialogue with your teams, eliminating misunderstandings and keeping everyone aligned.",
    textColor: "black",
  },
  {
    icon: Icon,
    heading: (
      <>
        <span className={styles.colorBlue}>Effective</span>Communication
      </>
    ),
    description:
      "We provide clear and open dialogue with your teams, eliminating misunderstandings and keeping everyone aligned.",
    textColor: "black",
  },
]

const IZEdge = ({ strapiData }) => {
  return (
    <div className={styles.izEdge}>
      <Container>
        <div className={styles.progress}>
          <div className={styles.headingWrapper}>
            <h2
              className={styles.heading}
              dangerouslySetInnerHTML={{ __html: strapiData?.title }}
            />

            <p
              className={styles.subDescription}
              dangerouslySetInnerHTML={{ __html: strapiData?.subTitle }}
            />
          </div>

          <Row className="gap-30">
            {strapiData?.cards?.map((e, i) => (
              <Col
                xl={3}
                lg={3}
                md={6}
                sm={12}
                key={i}
                className={`${styles.colGap} d-flex`}
              >
                <div className={`${styles.card} ${styles[`card${i + 1}`]}`}>
                  {/* <img
                    src={e?.image1[0]?.localFile?.publicURL}
                    decoding="async"
                    loading="lazy"
                    alt={"icon"}
                  /> */}
                  <p
                    className={styles.heading}
                    dangerouslySetInnerHTML={{
                      __html: e?.title,
                    }}
                  />
                  <p
                    className={`${styles.description} ${styles[i.textColor]}`}
                    dangerouslySetInnerHTML={{
                      __html: e?.subTitle,
                    }}
                  />
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default IZEdge
