// extracted by mini-css-extract-plugin
export var arrowAnimation = "IZ-Contribute-module--arrowAnimation--da0d3";
export var black = "IZ-Contribute-module--black--9537d";
export var card = "IZ-Contribute-module--card--7a6c5";
export var card1 = "IZ-Contribute-module--card1--5df3a";
export var card2 = "IZ-Contribute-module--card2--6f154";
export var card3 = "IZ-Contribute-module--card3--71b2d";
export var colGap = "IZ-Contribute-module--colGap--22736";
export var colorBlue = "IZ-Contribute-module--colorBlue--53a33";
export var concerns = "IZ-Contribute-module--concerns--5b8c9";
export var description = "IZ-Contribute-module--description--5576c";
export var heading = "IZ-Contribute-module--heading--98015";
export var headingWrapper = "IZ-Contribute-module--headingWrapper--a5d2f";
export var izEdge = "IZ-Contribute-module--izEdge--b76b1";
export var newBtn = "IZ-Contribute-module--newBtn--f543b";
export var progress = "IZ-Contribute-module--progress--a28ab";
export var subDescription = "IZ-Contribute-module--subDescription--1c0fd";
export var subHeading = "IZ-Contribute-module--subHeading--8558c";
export var talkBtn = "IZ-Contribute-module--talkBtn--88d23";
export var white = "IZ-Contribute-module--white--9590b";