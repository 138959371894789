import React from "react"
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import "./SQAServices.scss"

const SQAServices = ({ strapiData }) => {
  const columnSizes = [8, 4, 4, 8]
  return (
    <div className="sqa-services-container">
      <Container>
        <h2
          className="mb-3"
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <p className="mx-auto mb-5">{strapiData.subTitle}</p>
        <Row>
          {strapiData.cards.map((step, index) => (
            <Col
              key={index}
              lg={
                index === strapiData.cards.length - 1
                  ? 12 // Ensure the last card takes 100% width
                  : columnSizes[index % 4] // Repeat pattern for every group of 4
              }
              className="step-col"
            >
              <div
                className={`step-card ${step.bgClass} ${
                  index === 0
                    ? "bgClassOne"
                    : index === 1
                    ? "bgClassTwo"
                    : index === 2
                    ? "bgClassThree"
                    : index === 3
                    ? "bgClassFour"
                    : "bgClassFive"
                }`}
              >
                <h3>{step.title}</h3>
                <p>{step.subTitle}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default SQAServices
