import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import "./AsCodeService.scss"

const dataIac = [
  {
    title: "Infrastructure As Code (IaC)",
    items: [
      {
        id: "01",
        desc: "A technology for managing and provisioning infrastructure through code and automation.",
      },
      {
        id: "02",
        desc: "Automating infrastructure management and deployment using code.",
      },
      {
        id: "03",
        desc: "Used for defining and managing infrastructure in a repeatable and automated manner.",
      },
      {
        id: "04",
        desc: "Streamlines development processes by reducing manual intervention and delays.",
      },
      {
        id: "05",
        desc: "Broad, including cloud and on-premises environments.",
      },
    ],
  },
]

const dataIaas = [
    {
        title: "Infrastructure As A Service (IaaS)",
        items: [
          {
            id: "01",
            desc: "A cloud service model that provides virtualized computing resources over the internet.",
          },
          {
            id: "02",
            desc: "Providing scalable virtual servers and storage for deploying and managing applications.",
          },
          {
            id: "03",
            desc: "Used for hosting and running applications, including virtual machines, storage, and networking.",
          },
          {
            id: "04",
            desc: "Offers scalable resources and virtual environments to run applications.",
          },
          {
            id: "05",
            desc: "Primarily cloud-based, providing resources on-demand.",
          },
        ],
      },
]

const ComparisonSection = () => {

  return (
    <div className="comparison-section">
      <Container className="py-5">
        <h2 className="text-center">
          <span className="title-highlight">
            Infrastructure As Code Services
          </span>{" "}
          Vs <span className="title-highlight">Infrastructure As Services</span>
        </h2>
        <p className="text-center mb-4">
          See the difference between both these terms. Connect with us in case
          you need either of them:
        </p>
        <Row>
          <Col lg={6} md={12} className="mb-3">
            <div className="heading">Infrastructure As Code (IaC)</div>
            {dataIac[0].items.map((item, index) => (
                <div key={index} className="paragraph" style={{ backgroundColor: index % 2 === 0 ? "#F2F2F2" : "#EBEBEB" }}>
              <div className="numb">{item?.id}</div>
                <div className="desc">{item.desc}</div>
              </div>
            ))}
          </Col>
          <Col lg={6} md={12} className="mb-3">
            <div className="heading">Infrastructure As Code (IaaS)</div>
            {dataIaas[0].items.map((item, index) => (
              <div key={index} className="paragraph" style={{ backgroundColor: index % 2 === 0 ? "#EBEBEB" : "#F2F2F2" }}>
              <div className="numb">{item?.id}</div>
                <div className="desc">{item.desc}</div>
              </div>
            ))}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ComparisonSection
