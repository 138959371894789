// extracted by mini-css-extract-plugin
export var SliderWidth = "BusinessSector-module--SliderWidth--e979b";
export var arrowPoint = "BusinessSector-module--arrowPoint--bb724";
export var blurInOut = "BusinessSector-module--blurInOut--d2608";
export var businessSector = "BusinessSector-module--businessSector--72ab7";
export var buton = "BusinessSector-module--buton--b6db3";
export var cardContent = "BusinessSector-module--cardContent--caa49";
export var cardFooter = "BusinessSector-module--cardFooter--4ae04";
export var cardHover = "BusinessSector-module--cardHover--4088f";
export var cards = "BusinessSector-module--cards--5664e";
export var description = "BusinessSector-module--description--a81d5";
export var greyCard = "BusinessSector-module--greyCard--e178f";
export var heading = "BusinessSector-module--heading--b2ee8";
export var headingContainer = "BusinessSector-module--headingContainer--e99fb";
export var iconContainer = "BusinessSector-module--iconContainer--0781a";
export var iconContainerLeft = "BusinessSector-module--iconContainerLeft--f0eea";
export var image2 = "BusinessSector-module--image2--6a8ef";
export var indDec = "BusinessSector-module--indDec--e4028";
export var newBtn = "BusinessSector-module--newBtn--0ff50";
export var points = "BusinessSector-module--points--731bd";
export var portfolioArrowIcon = "BusinessSector-module--portfolioArrowIcon--a1966";
export var portfolioArrowIconCover = "BusinessSector-module--portfolioArrowIconCover--d55cf";
export var portfolioArrowRightIconCover = "BusinessSector-module--portfolioArrowRightIconCover--58298";
export var redCard = "BusinessSector-module--redCard--2de6b";
export var sheildImgTop = "BusinessSector-module--sheildImgTop--aa740";
export var shieldTopBannerImg = "BusinessSector-module--shieldTopBannerImg--abd32";
export var skill = "BusinessSector-module--skill--81044";
export var text = "BusinessSector-module--text--3216a";