// extracted by mini-css-extract-plugin
export var description = "OurEmergingTechnology-module--description--2519f";
export var heading = "OurEmergingTechnology-module--heading--73fcf";
export var pointCard = "OurEmergingTechnology-module--pointCard--db120";
export var pointTitle = "OurEmergingTechnology-module--pointTitle--7cfab";
export var rightCard = "OurEmergingTechnology-module--rightCard--9a799";
export var rightCardDescription = "OurEmergingTechnology-module--rightCardDescription--46bf0";
export var rightCardHeading = "OurEmergingTechnology-module--rightCardHeading--6f961";
export var sectionContainer = "OurEmergingTechnology-module--sectionContainer--e1424";
export var selected = "OurEmergingTechnology-module--selected--563e2";
export var smallCard = "OurEmergingTechnology-module--smallCard--bdbbb";
export var title = "OurEmergingTechnology-module--title--848b9";