// extracted by mini-css-extract-plugin
export var SliderWidth = "HorizonMobile-module--SliderWidth--7dde8";
export var Trust = "HorizonMobile-module--Trust--e3c98";
export var arrowPoint = "HorizonMobile-module--arrowPoint--7eb0b";
export var buton = "HorizonMobile-module--buton--d9918";
export var cardContent = "HorizonMobile-module--cardContent--ad0fd";
export var cards = "HorizonMobile-module--cards--14899";
export var dec = "HorizonMobile-module--dec--881d5";
export var heading = "HorizonMobile-module--heading--a3f5a";
export var image2 = "HorizonMobile-module--image2--b8a34";
export var indDec = "HorizonMobile-module--indDec--74840";
export var points = "HorizonMobile-module--points--85e9c";
export var sheildImgTop = "HorizonMobile-module--sheildImgTop--4e3ba";
export var shieldTopBannerImg = "HorizonMobile-module--shieldTopBannerImg--c3d59";
export var skill = "HorizonMobile-module--skill--60cdd";
export var text = "HorizonMobile-module--text--b6b1a";