// extracted by mini-css-extract-plugin
export var SliderWidth = "WhyChooseOurIZ-module--SliderWidth--f03e9";
export var Trust = "WhyChooseOurIZ-module--Trust--e0e75";
export var card = "WhyChooseOurIZ-module--card--5fc6f";
export var cardHeading = "WhyChooseOurIZ-module--cardHeading--0a43b";
export var desc = "WhyChooseOurIZ-module--desc--e2c7c";
export var description = "WhyChooseOurIZ-module--description--09cd3";
export var heading = "WhyChooseOurIZ-module--heading--21bb9";
export var headingAndDesc = "WhyChooseOurIZ-module--headingAndDesc--90c84";
export var iconContainer = "WhyChooseOurIZ-module--iconContainer--bcf2d";
export var iconContainerLeft = "WhyChooseOurIZ-module--iconContainerLeft--8b548";
export var imgContainer = "WhyChooseOurIZ-module--imgContainer--d7d0a";
export var portfolioArrowIcon = "WhyChooseOurIZ-module--portfolioArrowIcon--b7288";
export var portfolioArrowIconCover = "WhyChooseOurIZ-module--portfolioArrowIconCover--a4a6c";
export var portfolioArrowRightIconCover = "WhyChooseOurIZ-module--portfolioArrowRightIconCover--1e680";
export var sheildImgTop = "WhyChooseOurIZ-module--sheildImgTop--06c8d";
export var shieldTopBannerImg = "WhyChooseOurIZ-module--shieldTopBannerImg--ad370";