// extracted by mini-css-extract-plugin
export var arrowAnimation = "IZGurantee-module--arrowAnimation--5b0ac";
export var black = "IZGurantee-module--black--0f1d9";
export var card = "IZGurantee-module--card--b7ddb";
export var card1 = "IZGurantee-module--card1--d2d34";
export var card2 = "IZGurantee-module--card2--29366";
export var card3 = "IZGurantee-module--card3--c2e4c";
export var colGap = "IZGurantee-module--colGap--29d61";
export var concerns = "IZGurantee-module--concerns--eaf5e";
export var description = "IZGurantee-module--description--c1908";
export var heading = "IZGurantee-module--heading--30d38";
export var izGurantee = "IZGurantee-module--izGurantee--b4003";
export var newBtn = "IZGurantee-module--newBtn--bf86e";
export var progress = "IZGurantee-module--progress--3b9d5";
export var secondIndex = "IZGurantee-module--secondIndex--da784";
export var subDescription = "IZGurantee-module--subDescription--8061f";
export var subHeading = "IZGurantee-module--subHeading--0c421";
export var talkBtn = "IZGurantee-module--talkBtn--228cf";
export var white = "IZGurantee-module--white--2f077";