// extracted by mini-css-extract-plugin
export var SliderWidth = "success-stories-module--SliderWidth--ba077";
export var arrowIcon = "success-stories-module--arrowIcon--3bac4";
export var arrowImage = "success-stories-module--arrowImage--c5fe8";
export var arrowLink = "success-stories-module--arrowLink--226c4";
export var blurrEffect = "success-stories-module--blurrEffect--04eb0";
export var btn = "success-stories-module--btn--a07e3";
export var btn1 = "success-stories-module--btn1--30fad";
export var btn2 = "success-stories-module--btn2--13e34";
export var caseImages = "success-stories-module--caseImages--1a76a";
export var clientImgs = "success-stories-module--clientImgs--b3eea";
export var content = "success-stories-module--content--b3bed";
export var heading = "success-stories-module--heading--0390a";
export var logoImages = "success-stories-module--logoImages--00c95";
export var logos = "success-stories-module--logos--2a796";
export var rowGap = "success-stories-module--rowGap--8026b";
export var successStories = "success-stories-module--successStories--20ba1";
export var talkBtn = "success-stories-module--talkBtn--7a655";
export var talkBtn2 = "success-stories-module--talkBtn2--ea6db";