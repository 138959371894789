import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./InstantRecap.module.scss"

const InstantRecap = ({ strapiData }) => {
    return (
        <div className={styles.sectionContainer}>
            <Container>
                <div>
                    <h2
                        className={styles.subTitle}
                        dangerouslySetInnerHTML={{
                            __html: strapiData?.title,
                        }} />
                </div>

                <Row className={styles.cardsContainer}>
                    {
                        strapiData?.cards?.map((el, ind) =>  <Col xl={4} lg={6} md={6} sm={12} xs={12}>
                            <div className={styles.card}>
                                <div className={styles.cardCounting}><p>{`0${ind+1}`}</p></div>
    
                                <h3 className={styles.cardHeading}>
                                    {el?.title}
                                </h3>
                                <p className={styles.description}>
                                    {el?.subTitle}
                                </p>
                            </div>
                        </Col>)
                    }
                   
                  
                </Row>
            </Container>
        </div>
    )
}
export default InstantRecap;