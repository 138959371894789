import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./ExploreProjects.module.scss"

const cardData = [
  {
    subHeading: "2-4 Weeks",
    heading: "For A Prototype",
    description:
      "Develop a clickable prototype to pitch your idea to investors and gather early feedback from users.",
    textColor: "black",
    button: "Get Started",
    link: "/contact-us/",
  },
  {
    subHeading: "2-3 Months",
    heading: "For An  MVP",
    description:
      "Launch your Minimum Viable Product (MVP) to collect insights from early adopters and identify key areas for improvement.",
    textColor: "white",
    button: "Explore More",
    link: "/contact-us/",
  },
  {
    subHeading: "1 Day-2 Weeks",
    heading: "For New Features",
    description:
      "Enjoy rapid updates and feature launches, allowing you to enhance your product without waiting for major releases.",
    button: "Need a Fix",
    textColor: "black",
    link: "/software-project-rescue/",
  },
]

const ExploreProjects = ({ calendlyPage, strapiData }) => {
  return (
    <div
      className={`${styles.exploreProjects} ${
        calendlyPage && styles.calendlyPageMargin
      }`}
    >
      <Container>
        <div className={styles.headingContainer}>
          <Row
            className={`${styles.concernsRow} justify-content-between align-items-center`}
          >
            <div>
              <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
              <p dangerouslySetInnerHTML={{ __html: strapiData?.subTitle }} />
            </div>
            <div className={styles.cardFooter}>
              <Link
                to={strapiData?.buttons[0]?.url}
                className="computerVisionButton"
              >
                {strapiData?.buttons[0]?.title}
              </Link>
            </div>
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default ExploreProjects
