import React from "react"
import * as styles from "./Revolutionizing.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import { Col } from "react-bootstrap"
import { Link } from "gatsby"



const Revolutionizing = ({ strapiData, projectEngRTest }) => {
  const data = [
    {
      name: "Pre-Seed",
      skills: [
        "Nuruting initial ideas into viable businesses.",
        "Guiding product development.",
        "A strong foundation for startup success.",
      ],
    },
    {
      name: "Seed",
      skills: [
        "Refining product-market fit.",
        "Providing mentorship and resources for growth.",
        "Establishing groundwork for future success.",
      ],
    },
    {
      name: "Series A",
      skills: [
        "Scaling operations  & expanding theme.",
        "Connecting with Series A Investors.",
        "Growth for the next phase.",
      ],
    },
    {
      name: "Series B",
      skills: [
        "Rapidly scaling and expanding product offerings.",
        "Providing strategic support for growth initiatives.",
        "Fueling growth for market dominance.",
      ],
    },
    {
      name: "Series C",
      skills: [
        "Streamlined Process Consultancy.",
        "Diverse Product and Engineering Teams.",
        "Balancing growth objectives.",
      ],
    },
    {
      name: "Growth Acquisition",
      skills: [
        "Technology partner for startups with expanded reach.",
        "Accelerated growth through acquisitions.",
        "Targeted marketing to capture new markets.",
      ],
    },
  ]

  console.log(strapiData);


  return (
    <div className={styles.Trust}>
      <React.Fragment>
        <Container>
          <Row>
            <Col xl={4} lg={12} md={12} sm={12} xs={12} className="p-0">
              <h2
                className={styles.heading}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.title,
                }}
              />
            </Col>

            <Col xl={5} lg={6} md={12} sm={12} xs={12}>
              <p className={styles.description}>
                {strapiData?.subTitle}
              </p>
            </Col>
            <Col xl={3} lg={6} md={12} sm={12} xs={12} className={styles.cta}>
              <Link to={strapiData?.buttons[0]?.url}>
                <div className={`blueButton`}>
                  {strapiData?.buttons?.[0] && (
                    <p>{"Expand Your Squad"}</p>
                  )}
                </div>
              </Link>
            </Col>
          </Row>
          <Row>
            {strapiData?.cards?.map((el, i) => (
              <Col key={i} xs={12} sm={12} md={6} className="d-flex">
                <div className={styles.slideWrapper}>
                  <div
                    className={`${styles.cards} ${i % 2 === 0 ? styles.greyCard : styles.redCard
                      } ${styles.cardHover}`}
                  >
                    <div className={styles.cardContent}>
                      <div><div className={styles.cardCounting}>0{i + 1}</div></div>
                      <h3>{el?.title}</h3>
                    </div>
                    <div className={styles.cardDescription}>
                     <p>{el?.subTitle}</p>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </React.Fragment>
    </div>
  )
}

export default Revolutionizing;
