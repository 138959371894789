import React from "react"
import { Link } from "gatsby"
import * as styles from "./Trusted.module.scss"
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"

function Trusted({ strapiData, mobileApp, sqaTrusted }) {
  return (
    <div
      className={`${styles.trusted}  ${sqaTrusted ? styles.sqaTrustedBg : ""}`}
    >
      <Container>
        <Row>
          <Col lg={6}>
            <h2
              className={styles.heading}
              dangerouslySetInnerHTML={{ __html: strapiData?.title }}
            />
            <div
              className={`${styles.serBtn} ${
                mobileApp ? styles.mobileAppBtn : ""
              }`}
            >
              <Link
                to={"/contact-us/"}
                className="newBtn mb-5"
                // style={{ color: "white" }}
              >
                Let’s Make It Happen
              </Link>
            </div>
          </Col>
          <Col lg={6}>
            <div className={styles.trustedCards}>
              {strapiData?.cards?.map((e, i) => (
                <ul className={styles.blueBullets}>
                  <p
                    className={`${styles.points} ${
                      mobileApp ? styles.mobileAppPoints : ""
                    }`}
                  >
                    {e?.title}
                  </p>
                </ul>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Trusted
