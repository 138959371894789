import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Row from "react-bootstrap/Row"
import * as styles from "./Banner.module.scss"
import { Container } from "react-bootstrap"

const Banner = ({ strapiData, custome }) => {
  const image = getImage(strapiData?.secImages[0]?.localFile)

  return (
    <div
      className={`${styles.discoveryBanner} ${
        custome ? styles.customeBanner : ""
      }`}
    >
      <Container>
        <Row>
          <Col xl={6} lg={12} md={12}>
            <div className={styles.heading}>
              <h1
                className={`${styles.mainTitle} ${
                  custome ? styles.customeHead : ""
                }`}
                dangerouslySetInnerHTML={{ __html: strapiData?.title }}
              />
              <p
                className={`${styles.bannerHeading} ${
                  custome ? styles.customeBanner : ""
                }`}
                dangerouslySetInnerHTML={{ __html: strapiData?.subTitle }}
              />
              <p
                className={`${styles.description}
                `}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.description?.description,
                }}
              />
            </div>
            <Link to={strapiData?.buttons[0]?.url}>
              <div className={`${styles.btns} blueButton mx-auto mx-xl-0`}>
                {strapiData?.buttons[0] && (
                  <p>{strapiData?.buttons[0]?.title}</p>
                )}
              </div>
            </Link>
          </Col>
          <Col xl={6} lg={12} md={12} className={styles.banner_hero}>
            {image ? (
              <GatsbyImage
                placeholder="blurred"
                decoding="async"
                loading="lazy"
                image={image}
                alt={strapiData?.secImages[0]?.alternativeText}
                c
              />
            ) : (
              <img
                alt={strapiData?.secImages[0]?.alternativeText}
                src={strapiData?.secImages[0]?.localFile?.publicURL}
                decoding="async"
                loading="lazy"
                className={styles.banerImg}
              />
            )}
          </Col>
        </Row>
      </Container>
      <img
        className={styles.curve}
        src="https://invozone-backend.s3.us-east-1.amazonaws.com/line_vector_c415196118.png"
      />
    </div>
  )
}

export default Banner
