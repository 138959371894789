// extracted by mini-css-extract-plugin
export var arrowAnimation = "Banner-module--arrowAnimation--4335b";
export var banner = "Banner-module--banner--b73fd";
export var bannerHeading = "Banner-module--bannerHeading--70e4a";
export var btns = "Banner-module--btns--5b679";
export var curve = "Banner-module--curve--62d86";
export var customeBanner = "Banner-module--customeBanner--50e64";
export var customeBtn = "Banner-module--customeBtn--e87c2";
export var customeBtn2 = "Banner-module--customeBtn2--340a4";
export var customeHead = "Banner-module--customeHead--300e7";
export var description = "Banner-module--description--2d52d";
export var heading = "Banner-module--heading--c1b47";
export var mainTitle = "Banner-module--mainTitle--a6754";
export var newBtn = "Banner-module--newBtn--eca80";
export var newBtn2 = "Banner-module--newBtn2--32691";
export var spanColor = "Banner-module--spanColor--b5802";
export var spanText = "Banner-module--spanText--57b7d";