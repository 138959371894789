// extracted by mini-css-extract-plugin
export var SliderWidth = "WhyChoose-module--SliderWidth--68f97";
export var card = "WhyChoose-module--card--cce46";
export var cardWrapper = "WhyChoose-module--cardWrapper--6c7c4";
export var description = "WhyChoose-module--description--ac860";
export var fineBg = "WhyChoose-module--fineBg--d4f13";
export var heading = "WhyChoose-module--heading--8b489";
export var headingPremium = "WhyChoose-module--headingPremium--35ecc";
export var iconContainer = "WhyChoose-module--iconContainer--0afb2";
export var iconContainerLeft = "WhyChoose-module--iconContainerLeft--9c009";
export var portfolioArrowIcon = "WhyChoose-module--portfolioArrowIcon--3d940";
export var portfolioArrowIconCover = "WhyChoose-module--portfolioArrowIconCover--faa83";
export var portfolioArrowRightIconCover = "WhyChoose-module--portfolioArrowRightIconCover--7fcd3";
export var premiumImg = "WhyChoose-module--premiumImg--93977";
export var teamButton = "WhyChoose-module--teamButton--d2205";
export var whyChoose = "WhyChoose-module--whyChoose--70c32";