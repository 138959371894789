import { graphql, Script } from "gatsby"
import React from "react"
import ContactSales from "../../components/common/ContactSales"
import Faqs from "../../components/common/Faqs2"
import MainLayout from "../../layouts/MainLayout3"
import Banner from "../../components/ai-development-services-1/Banner"
import TrustInvoZone from "../../components/home-sections/ClientLogos"
import ExploreHowWeTransform from "../../components/ai-development-services-1/WorkPortfolio"
import WordsFromClients from "../../components/ai-development-services-1/WordsFromClients"
import EngineerAHightPerformanceNLP from "../../components/nlp-services/EngineerAHightPerformanceNLP"
import WhyChooseOurNLP from "../../components/nlp-services/WhyChooseOurNLP"
import NLPExpertise from "../../components/nlp-services/NLPExpertise"
import MeetNova from "../../components/nlp-services/MeetNova"

import SEORevamp from "../../components/common/SEO_Revamp"

export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })
  return (
    <>
      <Script
        src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
        strategy="post-hydrate"
      />

      <SEORevamp
        title={metaTitle}
        description={metaDescription}
        schemas={headSchema}
        image="https://invozone-backend.s3.amazonaws.com/hire_elixir_developers_f701d7b38c.webp"
      />
    </>
  )
}

const AIDevelopment = ({ data }) => {

  const heroSection = data?.strapiPage?.sections[0]
  const hightPerformanceNLP = data?.strapiPage?.sections[1]
  const meetNova = data?.strapiPage?.sections[2]
  const nlpExpertise = data?.strapiPage?.sections[3]
  const whyChoose = data?.strapiPage?.sections[4]
  const wordsFromClient = data?.strapiPage?.sections[5]
  const exploreHowWe = data?.strapiPage?.sections[6]
  const faqs = data?.strapiPage?.sections[7]
  
 

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })


  return (
    <MainLayout bgChanged={true} schemas={bodySchema}>
      <Banner strapiData={heroSection} breadCrumb={data?.strapiPage?.buttons} />
      <TrustInvoZone hideCurve={true} />
      <EngineerAHightPerformanceNLP strapiData={hightPerformanceNLP} />
      <MeetNova strapiData={meetNova}  />
      <NLPExpertise strapiData={nlpExpertise} />
      <WhyChooseOurNLP strapiData={whyChoose} />
      <WordsFromClients strapiData={wordsFromClient} />
      <ExploreHowWeTransform strapiData={exploreHowWe} />
      <Faqs strapiData={faqs} pageName="Staff Augmentation Company" />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query mobileAppDevelopments {
    strapiPage(slug: { eq: "nlp-services" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          title2
          subTitle
          jsonData{
          aiPowered {
              services{
                serLink
                serName
              }
            }
          } 
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
          
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
  }
`

export default AIDevelopment;

// export const Head = ({ data }) => {
//   const { metaTitle, metaDescription } = data?.strapiPage?.seo
//   let headSchema = []
//   const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
//     return v?.visibilityIn === true ? v : headSchema?.push(v) && false
//   })
//   return (
//     <SEORevamp
//       title={metaTitle}
//       description={metaDescription}
//       schemas={headSchema}
//       image="https://invozone-backend.s3.amazonaws.com/staff_augmentation_company_7031a2796c.svg"
//     />
//   )
// }
