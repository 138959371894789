// extracted by mini-css-extract-plugin
export var aiPoweredCard = "SweetSpot-module--aiPoweredCard--8e903";
export var cardContent = "SweetSpot-module--cardContent--6dd26";
export var cardDescription = "SweetSpot-module--cardDescription--a6a28";
export var counting = "SweetSpot-module--counting--f10fe";
export var desc = "SweetSpot-module--desc--f836c";
export var headingContainer = "SweetSpot-module--headingContainer--437d6";
export var keyPoint = "SweetSpot-module--keyPoint--95ecc";
export var keyPoints = "SweetSpot-module--keyPoints--f68b4";
export var sectionContainer = "SweetSpot-module--sectionContainer--f965d";
export var subTitle = "SweetSpot-module--subTitle--88b84";
export var title = "SweetSpot-module--title--f614d";