// extracted by mini-css-extract-plugin
export var Trust = "SuccessfulProjects-module--Trust--eb897";
export var arrowIcon = "SuccessfulProjects-module--arrowIcon--c900e";
export var arrowImage = "SuccessfulProjects-module--arrowImage--35cdc";
export var arrowLink = "SuccessfulProjects-module--arrowLink--dd7b3";
export var btn = "SuccessfulProjects-module--btn--cc026";
export var btn2 = "SuccessfulProjects-module--btn2--c7fb7";
export var caseImages = "SuccessfulProjects-module--caseImages--6cb69";
export var clientImgs = "SuccessfulProjects-module--clientImgs--93775";
export var content = "SuccessfulProjects-module--content--6412b";
export var desc = "SuccessfulProjects-module--desc--3b700";
export var headSearch = "SuccessfulProjects-module--headSearch--8fcc6";
export var heading = "SuccessfulProjects-module--heading--06d83";
export var logoImages = "SuccessfulProjects-module--logoImages--2b8a2";
export var logos = "SuccessfulProjects-module--logos--2db2a";
export var rowGap = "SuccessfulProjects-module--rowGap--2e842";
export var talkBtn = "SuccessfulProjects-module--talkBtn--1300d";