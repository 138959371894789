// extracted by mini-css-extract-plugin
export var SliderWidth = "HorizonMobile-module--SliderWidth--ca0d1";
export var Trust = "HorizonMobile-module--Trust--80d47";
export var arrowPoint = "HorizonMobile-module--arrowPoint--04bdf";
export var buton = "HorizonMobile-module--buton--22b5e";
export var cards = "HorizonMobile-module--cards--2304f";
export var dec = "HorizonMobile-module--dec--aa122";
export var heading = "HorizonMobile-module--heading--8f747";
export var image2 = "HorizonMobile-module--image2--35214";
export var indDec = "HorizonMobile-module--indDec--f2fef";
export var points = "HorizonMobile-module--points--d39ea";
export var sheildImgTop = "HorizonMobile-module--sheildImgTop--f5317";
export var shieldTopBannerImg = "HorizonMobile-module--shieldTopBannerImg--c057f";
export var skill = "HorizonMobile-module--skill--2dd25";
export var text = "HorizonMobile-module--text--efc8a";