// extracted by mini-css-extract-plugin
export var SliderWidth = "Partner-success-module--SliderWidth--eae61";
export var blueText = "Partner-success-module--blueText--97f1b";
export var clientContent = "Partner-success-module--clientContent--bbfdd";
export var coma = "Partner-success-module--coma--e2652";
export var content = "Partner-success-module--content--b8bb9";
export var customModal = "Partner-success-module--customModal--0dddc";
export var dec = "Partner-success-module--dec--e9b08";
export var designation = "Partner-success-module--designation--7208d";
export var dot = "Partner-success-module--dot--be891";
export var heading = "Partner-success-module--heading--346a0";
export var iconContainer = "Partner-success-module--iconContainer--29649";
export var iconContainerLeft = "Partner-success-module--iconContainerLeft--0c7db";
export var imageContainer = "Partner-success-module--imageContainer--561b5";
export var logos = "Partner-success-module--logos--13e89";
export var logosBg = "Partner-success-module--logosBg--0db1c";
export var name = "Partner-success-module--name--dc1a8";
export var partner = "Partner-success-module--partner--fcaaf";
export var playBtn = "Partner-success-module--playBtn--d544a";
export var portfolioArrowIcon = "Partner-success-module--portfolioArrowIcon--f8e15";
export var portfolioArrowIconCover = "Partner-success-module--portfolioArrowIconCover--6ad55";
export var portfolioArrowRightIconCover = "Partner-success-module--portfolioArrowRightIconCover--f1d08";
export var view = "Partner-success-module--view--2fc72";