import React from 'react';
import * as styles from './MeetNova.module.scss';
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Container } from 'react-bootstrap';

const MeetNova = ({ strapiData }) => {

    return (
        <div className={styles.sectionContainer}>
            <Container >
                <Row>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                        <h2
                            dangerouslySetInnerHTML={{
                                __html: strapiData?.title,
                            }}
                        />

                        <p
                            dangerouslySetInnerHTML={{
                                __html: strapiData?.subTitle,
                            }}
                        />

                        <Row>
                            {strapiData?.cards?.map((el) => <Col key={`nova_card_${el?.title}`} xl={6} lg={6} md={6} sm={12} xs={12}>
                                <div className={styles.card}>
                                    <img src={el?.image1?.[0]?.localFile?.publicURL} alt='nova_icon' />
                                    <p>
                                        {el?.title}
                                    </p>
                                </div>
                            </Col>)
                            }
                        </Row>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                        <div className={styles.novaCard}>
                            <img src={strapiData?.secImages?.[0]?.localFile?.publicURL} alt='nova_img' />
                            <div>
                                <h4
                                    dangerouslySetInnerHTML={{
                                        __html: strapiData?.subTitle2,
                                    }}
                                />
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: strapiData?.description?.description,
                                    }}
                                />
                            </div>
                        </div>

                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default MeetNova;