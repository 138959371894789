import React, { useRef, useEffect, useState } from "react"
import Container from "react-bootstrap/Container"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import * as styles from "./Four-Step.module.scss"

const FourStep = ({ strapiData, enterpriseBuild }) => {
  // console.log("🚀 ~ DevOpsBenefits ~ strapiData:", strapiData)
  const cardRefs = strapiData?.cards?.map(() => useRef())
  const [zIndexValues, setZIndexValues] = useState([])

  useEffect(() => {
    const handleScroll = () => {
      const newZIndexValues = cardRefs.map(cardRef => {
        if (cardRef.current) {
          const rect = cardRef.current.getBoundingClientRect()
          const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight

          if (isVisible) {
            return 1
          } else {
            return 0
          }
        }
        return 0
      })

      setZIndexValues(newZIndexValues)
    }

    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <div
      className={`${styles.fourStep} ${
        enterpriseBuild ? styles.enterpriseBuildBg : ""
      }`}
    >
      <Container>
        <div>
          <Row className={styles.start}>
            <Col lg={5} md={12} xs={12} sm={12} className={`${styles.leftCol}`}>
              <div className={styles.hire}>
                <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
                <div
                  className={styles.description}
                  dangerouslySetInnerHTML={{
                    __html: strapiData?.subTitle,
                  }}
                />
              </div>
            </Col>
            <Col
              lg={7}
              md={12}
              xs={12}
              sm={12}
              className={`${styles.cardWrapper} gap-30`}
            >
              {strapiData?.cards?.map((e, i) => (
                <div
                  key={i}
                  className={` ${styles.card} ${styles.stickyCard}`}
                  ref={cardRefs[i]}
                  style={{ zIndex: zIndexValues[i] }}
                >
                  <span className={styles.number}>0{i + 1}</span>
                  <div>
                    <div className={styles.headInline}>
                      <h3
                        dangerouslySetInnerHTML={{
                          __html: e?.title,
                        }}
                      ></h3>
                    </div>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: e?.description?.description,
                      }}
                    ></p>
                  </div>
                </div>
              ))}
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default FourStep
