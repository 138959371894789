// extracted by mini-css-extract-plugin
export var arrowAnimation = "DedicatedSoftwareTeam-module--arrowAnimation--9ed43";
export var blueBullets = "DedicatedSoftwareTeam-module--blueBullets--119c5";
export var boxContent = "DedicatedSoftwareTeam-module--boxContent--2b5e5";
export var bulletIcon = "DedicatedSoftwareTeam-module--bulletIcon--424ed";
export var cards = "DedicatedSoftwareTeam-module--cards--a37e7";
export var cardsContent = "DedicatedSoftwareTeam-module--cardsContent--ad935";
export var cardsZ = "DedicatedSoftwareTeam-module--cardsZ--82339";
export var desc = "DedicatedSoftwareTeam-module--desc--8a292";
export var featureItem = "DedicatedSoftwareTeam-module--featureItem--10031";
export var featuresList = "DedicatedSoftwareTeam-module--featuresList--10b00";
export var heading = "DedicatedSoftwareTeam-module--heading--023e8";
export var newBtn = "DedicatedSoftwareTeam-module--newBtn--b1fab";