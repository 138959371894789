import React from "react"
import { Link } from "gatsby"
import * as styles from "./Trusted.module.scss"
import { Col, Container, Row } from "react-bootstrap"

function Trusted({ strapiData }) {
  return (
    <div className={styles.trusted}>
      <Container>
        <Row className="align-items-center">
          <Col lg={5}>
            <h2
              className={styles.heading}
              dangerouslySetInnerHTML={{ __html: strapiData?.title }}
            />
          </Col>
          <Col lg={7}>
            <div className={styles.trustedCards}>
              {strapiData?.cards?.map((e, i) => (
                <ul className={styles.blueBullets}>
                  <p>{e?.title}</p>
                </ul>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Trusted
