// extracted by mini-css-extract-plugin
export var arrowAnimation = "ExploreProjects-module--arrowAnimation--8d57d";
export var blue = "ExploreProjects-module--blue--04ac7";
export var card1 = "ExploreProjects-module--card1--4f874";
export var card2 = "ExploreProjects-module--card2--ebeb2";
export var card3 = "ExploreProjects-module--card3--a68cd";
export var concernsRow = "ExploreProjects-module--concernsRow--26216";
export var exploreProjects = "ExploreProjects-module--exploreProjects--5789c";
export var headingContainer = "ExploreProjects-module--headingContainer--00194";
export var newBtn = "ExploreProjects-module--newBtn--3477a";
export var talkBtn = "ExploreProjects-module--talkBtn--9f422";