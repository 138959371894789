// extracted by mini-css-extract-plugin
export var aiPoweredCard = "VisionDevelopment-module--aiPoweredCard--e3cce";
export var blueStar = "VisionDevelopment-module--blueStar--04af4";
export var cardContent = "VisionDevelopment-module--cardContent--c1284";
export var cardDescription = "VisionDevelopment-module--cardDescription--456b1";
export var counting = "VisionDevelopment-module--counting--d4467";
export var desc = "VisionDevelopment-module--desc--aa06a";
export var imageContainer = "VisionDevelopment-module--imageContainer--87650";
export var innerRow = "VisionDevelopment-module--innerRow--0f6ac";
export var keyPoint = "VisionDevelopment-module--keyPoint--00a02";
export var keyPoints = "VisionDevelopment-module--keyPoints--55cfa";
export var subTitle = "VisionDevelopment-module--subTitle--a58df";
export var title = "VisionDevelopment-module--title--1112f";
export var visionDevelopment = "VisionDevelopment-module--visionDevelopment--e8588";
export var whiteStar = "VisionDevelopment-module--whiteStar--3123a";