import React from "react"
import * as styles from "./Certification.module.scss"
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Slider from "react-slick"
const Certification = ({ strapiData }) => {
  const settings = {
    infinite: true,
    autoplay: true,
    slidesToShow: 8,
    speed: 500,
    dots: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1140,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 570,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  }

  const cards = [
    {
      certificate:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/image_309_81ae20d9fe.svg",
    },
    {
      certificate:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/image_313_51493e62f4.svg",
    },
    {
      certificate:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/image_315_34df911ef0.svg",
    },
    {
      certificate:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/image_314_486328824b.svg",
    },
    {
      certificate:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/image_316_5a51942eac.svg",
    },
    {
      certificate:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/image_310_14651714a9.svg",
    },
    {
      certificate:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/image_312_5465f55a24.svg",
    },
    {
      certificate:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/image_311_f9f427a24e.svg",
    },
    {
      certificate:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/image_308_321ce4cd68.svg",
    },
    {
      certificate:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/image_302_40496f5fcc.svg",
    },
    {
      certificate:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/image_304_f2056fd0f1.svg",
    },
    {
      certificate:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/image_301_ba0628a5f4.svg",
    },
    {
      certificate:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/image_306_f80b45c8ff.svg",
    },
    {
      certificate:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/image_305_c941d97cef.svg",
    },
    {
      certificate:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/image_307_8850e780a5.svg",
    },
    {
      certificate:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/aws_select_tier_services_1_4106d64eaa.svg",
    },
    {
      certificate:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/AWS_Badge_Amazon_1_d125d3d94b.svg",
    },
    {
      certificate:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/AWS_amazon_RDS_1_3eeb3f0761.svg",
    },
  ]

  return (
    <div className={styles.certification}>
      <React.Fragment>
        <Container>
        <div className={styles.certificationInner}>
            {/* <Col lg={3} md={6} sm={12} xs={12}> */}
            <p
            className={styles.heading}
            // dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          >
            Honoring Our DevOps <span>Milestones</span> &{" "}
            <span>Achievements</span>
          </p>
          {/* </Col> */}
          <Row className="align-items-center">
            <Col lg={12} md={12} sm={12} xs={12}>
              <Slider {...settings} className={styles.devOpsRefineImages}>
                {cards?.map((e, i) => (
                  <div key={i}>
                    <img
                      // src={e?.image1 && e?.image1[0]?.localFile?.publicURL}
                      src={e?.certificate}
                      alt="Certifications"
                      decoding="async"
                      loading="lazy"
                    />
                  </div>
                ))}
              </Slider>
            </Col>
          </Row>
        </div>
        </Container>
      </React.Fragment>
    </div>
  )
}

export default Certification
