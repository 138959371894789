import { graphql } from "gatsby"
import React from "react"
import ContactSales from "../components/common/ContactSales"
import Faqs from "../components/common/Faqs2"
import SEORevamp from "../components/common/SEO_Revamp"
import Banner from "../components/mobile-app-dev/Banner"
import HappyClient from "../components/mobile-app-dev/HappyClients"
import Stories from "../components/enterprise-software/Stories"
import Trusted from "../components/custom-software/Trusted"
import MobileAppServices from "../components/mobile-app-dev/MobileAppServices"
import ClientLogos from "../components/home-sections/ClientLogos"
import ClientLogosMobile from "../components/home-sections/ClientLogosMobile"
import Market from "../components/custom-software/Market"
import Blueprint from "../components/start-up/Approch"
import Need from "../components/mobile-app-dev/Scope"
import Trust from "../components/mobile-app-dev/Trust"
import Lowdown from "../components/mobile-app-dev/Lowdown"
import Industries from "../components/enterprise-software/Industries"
import Success from "../components/enterprise-software/Success"

import MainLayout from "../layouts/MainLayout"
export const Head = ({ data }) => {
  // const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })
  return (
    <SEORevamp
      // title={metaTitle}
      // description={metaDescription}
      schemas={headSchema}
      image="https://invozone-backend.s3.amazonaws.com/mobile_app_development_ea71dda0e9.svg"
    />
  )
}
const mobileApp = ({ data }) => {
  // console.log(data)

  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 767)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const heroSection = data?.strapiPage?.sections[0]
  const developmentService = data?.strapiPage?.sections[2]
  const globalIndustires = data?.strapiPage?.sections[3]
  // console.log(globalIndustires, "globalIndustires")
  const section4 = data?.strapiPage?.sections[3]
  const integrate = data?.strapiPage?.sections[4]
  const market = data?.strapiPage?.sections[5]
  const blueprint = data?.strapiPage?.sections[6]
  const need = data?.strapiPage?.sections[7]
  const trust = data?.strapiPage?.sections[8]
  const lowdown = data?.strapiPage?.sections[9]
  const workPortfolio = data?.strapiPage?.sections[11]
  const faqs = data?.strapiPage?.sections[10]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout bgChanged={false} schemas={bodySchema}>
      <Banner strapiData={heroSection} breadCrumb={data?.strapiPage?.buttons} />
      {!isMobile && !isTablet ? <ClientLogos /> : <ClientLogosMobile />}
      <MobileAppServices strapiData={developmentService} />
      <Stories strapiData={globalIndustires} mobileApp={true} />
      <Trusted strapiData={integrate} mobileApp={true} />
      <Market strapiData={market} mobileApp={true}/>
      <Blueprint strapiData={blueprint} />
      <Need strapiData={need} />
      <Trust strapiData={trust} />
      <Lowdown strapiData={lowdown} />
      <Industries mobileApp={true} />
      <Success mobileApp={true} />
      <Faqs strapiData={faqs} />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query mobileAppDevelopments {
    strapiPage(slug: { eq: "mobile-app-development-services" }) {
      sections {
        title
        subTitle
        cards {
          title
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
          jsonData {
            mobile_app_dev {
              android
              cross
              hybrid
              ios
              wearable
            }
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
  }
`

export default mobileApp
