import React, { useState } from "react"
import Nav from "react-bootstrap/Nav"
import Tab from "react-bootstrap/Tab"
import devepSer from "../../images/mobile-app-developmemt/devepSer.svg"
import { Link } from "gatsby"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Horizon.module.scss"
import "./Horizon.scss"

const data = [
  {
    name: "Cloud Migration Services",
    img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007751_1_519a5e8c49.svg",
    dec: "We're helping our clients migrate their databases and applications to the cloud smoothly and securely, ensuring a risk-free transition without any bets.",
    skills: [
      "Smooth and secure cloud transitions",
      "Migrate databases and apps effortlessly",
      "Risk-free cloud migration process",
      "Securely move operations to the cloud",
    ],
  },
  {
    name: "Cloud Managed Services",
    img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007752_1_441c1a8bed.svg",
    dec: "We typically handle day-to-day monitoring and management of cloud service environments as part of our cloud-managed services, including configuration management and more.",
    skills: [
      "Monitor and manage cloud environments",
      "Optimize cloud services daily",
      "Ensure high performance and reliability",
      "Handle configuration management seamlessly",
    ],
  },
  {
    name: "Logging and Monitoring",
    img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007749_1_5d9cfd744f.svg",
    dec: "Gain real-time insights with customizable dashboards, focusing on impactful metrics and powerful integrations.",
    skills: [
      "Real-time insights for smarter decisions",
      "Customizable dashboards for key metrics",
      "Powerful integrations for deeper analysis",
      "Monitor performance and track issues",
    ],
  },
  {
    name: "Security and compliance services",
    img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007756_1_cabe927ecd.svg",
    dec: "Deploy secure, reliable, and scalable websites, apps, or processes using complimentary cloud computing. Gain access to free AWS cloud servers with global availability.",
    skills: [
      "Secure websites, apps, and processes",
      "Ensure compliance with industry standards",
      "Reliable cloud computing with security",
      "Global AWS cloud availability for security",
    ],
  },
  {
    name: "CI/CD as a service",
    img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007753_1_fd1caa0e14.svg",
    dec: "By blending continuous integration and delivery pipelines, we deliver new features to market faster and with fewer errors.",
    skills: [
      "Speed up delivery with CI/CD",
      "Efficient integration and deployment pipelines",
      "Deploy faster with fewer errors",
      "Accelerate feature delivery to the market",
    ],
  },
  {
    name: "Infrastructure as code",
    img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007758_1_02cb1e6ad9.svg",
    dec: "We use tools like AWS CloudFormation and Terraform to define and automate the provisioning of your cloud resources.",
    skills: [
      "Automate cloud resource provisioning",
      "Use Terraform and CloudFormation tools",
      "Simplify infrastructure management and scaling",
      "Code-driven infrastructure for better control",
    ],
  },
  {
    name: "Containerization and orchestration ",
    img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007823_bc407e8c4d.svg",
    dec: "We use Docker and Kubernetes to encapsulate your applications into containers, making them portable and efficient.",
    skills: [
      "Package apps with Docker containers",
      "Efficient orchestration with Kubernetes tools",
      "Simplify application deployment and scaling",
      "Ensure portability across multiple platforms",
    ],
  },
  {
    name: "Performance optimization and security",
    img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007823_bc407e8c4d.svg",
    dec: "We analyze and optimize your applications and systems to achieve peak performance while executing robust security measures simultaneously.",
    skills: [
      "Optimize systems for peak performance",
      "Secure applications without sacrificing speed",
      "Achieve balanced security and performance",
      "Maximize efficiency with performance enhancements",
    ],
  },
  {
    name: "Automation and efficiency improvement",
    img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007823_bc407e8c4d.svg",
    dec: "By using automation frameworks & scripting languages, we simplify processes, reduce manual errors & accelerate time-to-market for your applications.",
    skills: [
      "Automate processes for faster execution",
      "Reduce manual errors with automation",
      "Improve efficiency through smart scripting",
      "Accelerate time-to-market with automation",
    ],
  },
]

const Horizon = ({ strapiData }) => {
  const [activeTab, setActiveTab] = useState(data[0]?.name) // Initialize with the first tab's name

  const handleTabClick = label => {
    setActiveTab(label)
  }

  return (
    <>
      <div className="p-85 suiteTabing">
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <Row className="align-items-center">
            <Tab.Container id="left-tabs-example" defaultActiveKey="Planning">
              <Col lg={6}>
                <div>
                  <Nav variant="pills" className={`flex-column navbarBlock`}>
                    {data?.map((e, i) => (
                      <Nav.Item key={i}>
                        <div
                          className={`tabLink ${
                            activeTab === e.name ? "active" : ""
                          }`}
                          onClick={() => handleTabClick(e.name)}
                        >
                          {e?.name}
                        </div>
                      </Nav.Item>
                    ))}
                  </Nav>
                </div>
              </Col>
              <Col lg={6}>
                <Tab.Content className="horizonBlock">
                  {data?.map(
                    (e, i) =>
                      activeTab === e.name && ( // Only render the active tab's content
                        <div key={i} className={`tab-pane active`}>
                          <div className={styles.boxContent}>
                            <div className={styles.fineBg}>
                              <img
                                decoding="async"
                                loading="lazy"
                                src={e?.img}
                                // src={e?.image1[0]?.localFile?.publicURL}
                                // alt={e?.image1[0]?.alternativeText}
                              />
                            </div>
                            <h3>{e?.name}</h3>
                            <p className={styles.cardDes}>{e?.dec}</p>
                            <ul className={styles.blueBullets}>
                              {e.skills.map((skill, index) => (
                                <li key={index}>{skill}</li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      )
                  )}
                </Tab.Content>
              </Col>
            </Tab.Container>
          </Row>
        </Container>
      </div>
    </>
  )
}
export default Horizon
