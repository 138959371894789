// extracted by mini-css-extract-plugin
export var SliderWidth = "ChallengesOfIT-module--SliderWidth--98f1a";
export var card = "ChallengesOfIT-module--card--c00ac";
export var cardWrapper = "ChallengesOfIT-module--cardWrapper--ec855";
export var challengesOfIT = "ChallengesOfIT-module--challengesOfIT--528c4";
export var description = "ChallengesOfIT-module--description--0d32e";
export var fineBg = "ChallengesOfIT-module--fineBg--c78f3";
export var firstChild = "ChallengesOfIT-module--firstChild--d999d";
export var heading = "ChallengesOfIT-module--heading--5722f";
export var headingPremium = "ChallengesOfIT-module--headingPremium--39c7c";
export var iconContainer = "ChallengesOfIT-module--iconContainer--c01b3";
export var iconContainerLeft = "ChallengesOfIT-module--iconContainerLeft--fdf63";
export var imageContainer = "ChallengesOfIT-module--imageContainer--e5bfa";
export var portfolioArrowIcon = "ChallengesOfIT-module--portfolioArrowIcon--c4070";
export var portfolioArrowIconCover = "ChallengesOfIT-module--portfolioArrowIconCover--3d13c";
export var portfolioArrowRightIconCover = "ChallengesOfIT-module--portfolioArrowRightIconCover--dcde7";
export var premiumImg = "ChallengesOfIT-module--premiumImg--7b571";
export var teamButton = "ChallengesOfIT-module--teamButton--2a1a5";