import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import * as styles from "./Best.module.scss"

function Bets({ strapiData }) {
  return (
    <div className={styles.bets}>
      <Container>
        <div className={styles.bestEngineer}>
          <h1
            className={`${styles.heading} `}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <p
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
        </div>
        <Row className="gap-30">
          {strapiData?.cards?.map((e, i) => (
            <Col
              lg={4}
              className={`${styles.colPad} gap-30 d-flex text-align-center`}
            >
              <div className={styles.card}>
                <img
                  src={e?.image1[0]?.localFile?.publicURL}
                  alt="tick"
                  className={styles.cardImg}
                />
                <img
                  src={e?.image2[0]?.localFile?.publicURL}
                  alt="tick"
                  className={styles.cardImg2}
                />
                <h3>{e?.title}</h3>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default Bets
