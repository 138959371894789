// extracted by mini-css-extract-plugin
export var black = "Trust-module--black--ca1db";
export var blue = "Trust-module--blue--2a15b";
export var card = "Trust-module--card--0dfd8";
export var card1 = "Trust-module--card1--cbea9";
export var card2 = "Trust-module--card2--4d8f9";
export var card3 = "Trust-module--card3--44358";
export var colGap = "Trust-module--colGap--3f46e";
export var description = "Trust-module--description--56d0b";
export var heading = "Trust-module--heading--2da00";
export var progress = "Trust-module--progress--b8e9b";
export var progressSec = "Trust-module--progressSec--c3095";
export var subDescription = "Trust-module--subDescription--25dbf";
export var subHeading = "Trust-module--subHeading--ba47a";
export var white = "Trust-module--white--66e7c";