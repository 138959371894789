// extracted by mini-css-extract-plugin
export var SliderWidth = "Mainframe-module--SliderWidth--32e02";
export var capabilities = "Mainframe-module--capabilities--61729";
export var card = "Mainframe-module--card--f5840";
export var cardWrapper = "Mainframe-module--cardWrapper--871d3";
export var description = "Mainframe-module--description--a1ab3";
export var fineBg = "Mainframe-module--fineBg--65696";
export var flexWrap = "Mainframe-module--flexWrap--6aa1f";
export var heading = "Mainframe-module--heading--c21ff";
export var headingPremium = "Mainframe-module--headingPremium--567a8";
export var iconContainer = "Mainframe-module--iconContainer--a5a29";
export var iconContainerLeft = "Mainframe-module--iconContainerLeft--1bef7";
export var imgWrapper = "Mainframe-module--imgWrapper--169c7";
export var leftWrap = "Mainframe-module--leftWrap--a4a48";
export var portfolioArrowIcon = "Mainframe-module--portfolioArrowIcon--13a48";
export var portfolioArrowIconCover = "Mainframe-module--portfolioArrowIconCover--5fbf3";
export var portfolioArrowRightIconCover = "Mainframe-module--portfolioArrowRightIconCover--0768c";
export var premiumImg = "Mainframe-module--premiumImg--781ba";
export var rightWrap = "Mainframe-module--rightWrap--c0d31";
export var teamButton = "Mainframe-module--teamButton--395cb";