// extracted by mini-css-extract-plugin
export var arrowAnimation = "Banner-module--arrowAnimation--6496c";
export var bannerHeading = "Banner-module--bannerHeading--bd090";
export var bnrImg = "Banner-module--bnrImg--4e579";
export var btnsOuter = "Banner-module--btnsOuter--c244e";
export var computerVisionBanner = "Banner-module--computerVisionBanner--c33fd";
export var curve = "Banner-module--curve--3278c";
export var customeHead = "Banner-module--customeHead--70663";
export var description = "Banner-module--description--2e5c1";
export var heading = "Banner-module--heading--dc364";
export var list = "Banner-module--list--a4879";
export var listItem = "Banner-module--listItem--a1f59";
export var mainTitle = "Banner-module--mainTitle--48ea7";
export var spanColor = "Banner-module--spanColor--3a5ec";
export var transparentBtn = "Banner-module--transparentBtn--6561b";