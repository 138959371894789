// extracted by mini-css-extract-plugin
export var aiPoweredCard = "NLPExpertise-module--aiPoweredCard--726ec";
export var cardContent = "NLPExpertise-module--cardContent--c39b5";
export var cardDescription = "NLPExpertise-module--cardDescription--77fd5";
export var counting = "NLPExpertise-module--counting--92fe4";
export var desc = "NLPExpertise-module--desc--c15c9";
export var headingContainer = "NLPExpertise-module--headingContainer--8b6d3";
export var keyPoint = "NLPExpertise-module--keyPoint--35de3";
export var keyPoints = "NLPExpertise-module--keyPoints--f313a";
export var sectionContainer = "NLPExpertise-module--sectionContainer--b1ec1";
export var subTitle = "NLPExpertise-module--subTitle--a5da2";
export var title = "NLPExpertise-module--title--7b951";