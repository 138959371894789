// extracted by mini-css-extract-plugin
export var bannerHeading = "Banner-module--bannerHeading--d0544";
export var bnrImg = "Banner-module--bnrImg--cf769";
export var btns = "Banner-module--btns--c0ee7";
export var curve = "Banner-module--curve--4cb32";
export var customeHead = "Banner-module--customeHead--509e0";
export var description = "Banner-module--description--b38c8";
export var discoveryBanner = "Banner-module--discoveryBanner--107cf";
export var heading = "Banner-module--heading--1c491";
export var list = "Banner-module--list--3a328";
export var listItem = "Banner-module--listItem--b619c";
export var mainTitle = "Banner-module--mainTitle--d89f9";
export var spanColor = "Banner-module--spanColor--3e414";