import React, { useRef, useEffect, useState } from "react"
import Container from "react-bootstrap/Container"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import * as styles from "./Approch.module.scss"

const DevOpsBenefits = ({
  strapiData,
  enterpriseBuild,
  custome,
  webApp,
  sqaSolution,
}) => {
  const cardRefs = strapiData?.cards?.map(() => useRef())
  const [zIndexValues, setZIndexValues] = useState([])

  useEffect(() => {
    const handleScroll = () => {
      const newZIndexValues = cardRefs.map(cardRef => {
        if (cardRef.current) {
          const rect = cardRef.current.getBoundingClientRect()
          const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight

          if (isVisible) {
            return 1
          } else {
            return 0
          }
        }
        return 0
      })

      setZIndexValues(newZIndexValues)
    }

    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <div
      className={`${styles.hireCyber} ${
        enterpriseBuild ? styles.enterpriseBuildBg : ""
      }`}
    >
      <Container>
        <div>
          <Row className={styles.start}>
            <Col lg={5} md={12} xs={12} sm={12} className={`${styles.leftCol}`}>
              <div className={styles.hire}>
                <h2
                  className={`${styles.heading} ${
                    webApp ? styles.webAppWalking : ""
                  }`}
                  dangerouslySetInnerHTML={{ __html: strapiData?.title }}
                />
                <div
                  className={styles.description}
                  dangerouslySetInnerHTML={{
                    __html: strapiData?.subTitle,
                  }}
                />
              </div>
            </Col>
            <Col
              lg={7}
              md={12}
              xs={12}
              sm={12}
              className={`${styles.cardWrapper} gap-30`}
            >
              {strapiData?.cards?.map((e, i) => (
                <div
                  key={i}
                  className={` ${styles.card} ${styles.stickyCard} ${
                    webApp ? styles.webAppCard : ""
                  }`}
                  ref={cardRefs[i]}
                  style={{ zIndex: zIndexValues[i] }}
                >
                  <span className={styles.number}>0{i + 1}</span>
                  <div>
                    <div className={styles.headInline}>
                      <h3
                        dangerouslySetInnerHTML={{
                          __html: e?.subTitle,
                        }}
                      ></h3>
                      <p
                        className={styles.subHeading}
                        dangerouslySetInnerHTML={{
                          __html: e?.title2,
                        }}
                      ></p>
                    </div>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: e?.description?.description,
                      }}
                    ></p>
                  </div>
                </div>
              ))}
            </Col>
          </Row>
        </div>
        <div
          className={`${styles.concerns} ${custome ? styles.customScale : ""} ${
            sqaSolution ? styles.sqaSolutions : ""
          }`}
        >
          <Row className="justify-content-between align-items-center">
            <h2 className={`${styles.concernsHead}  ${webApp ? styles.webPageHead : ""}`}>
              {sqaSolution
                ? "Get Started with Expert Testing Solutions!"
                : custome
                ? "Scale Your Business With Adaptable, Scalable Custom Software"
                : ""}
            </h2>

            <div className={styles.cardFooter}>
              <Link
                to={"/contact-us/"}
                className="newBtn"
                style={{ color: "white" }}
              >
                Schedule a Demo
              </Link>
            </div>
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default DevOpsBenefits
