// extracted by mini-css-extract-plugin
export var arrowAnimation = "Banner-module--arrowAnimation--667e4";
export var banner = "Banner-module--banner--0eff1";
export var bannerHeading = "Banner-module--bannerHeading--c952a";
export var btns = "Banner-module--btns--28130";
export var curve = "Banner-module--curve--b508e";
export var customeBanner = "Banner-module--customeBanner--6acae";
export var customeBtn = "Banner-module--customeBtn--fd6d9";
export var customeBtn2 = "Banner-module--customeBtn2--87fdb";
export var customeHead = "Banner-module--customeHead--19ff1";
export var description = "Banner-module--description--1ead1";
export var heading = "Banner-module--heading--d61c4";
export var mainTitle = "Banner-module--mainTitle--92aaf";
export var newBtn = "Banner-module--newBtn--c377b";
export var newBtn2 = "Banner-module--newBtn2--439bb";
export var spanColor = "Banner-module--spanColor--274d0";