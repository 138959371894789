// extracted by mini-css-extract-plugin
export var SliderWidth = "Revolutionizing-module--SliderWidth--e8658";
export var Trust = "Revolutionizing-module--Trust--9008b";
export var buton = "Revolutionizing-module--buton--b3f66";
export var cardContent = "Revolutionizing-module--cardContent--e44f0";
export var cardCounting = "Revolutionizing-module--cardCounting--82bd3";
export var cardDescription = "Revolutionizing-module--cardDescription--8a9ed";
export var cards = "Revolutionizing-module--cards--c2d44";
export var container = "Revolutionizing-module--container--8d8b2";
export var cta = "Revolutionizing-module--cta--0e17e";
export var description = "Revolutionizing-module--description--27cae";
export var heading = "Revolutionizing-module--heading--5ac51";
export var iconContainer = "Revolutionizing-module--iconContainer--fc162";
export var iconContainerLeft = "Revolutionizing-module--iconContainerLeft--093f0";
export var indDec = "Revolutionizing-module--indDec--8bf38";
export var portfolioArrowIcon = "Revolutionizing-module--portfolioArrowIcon--13cbd";
export var portfolioArrowIconCover = "Revolutionizing-module--portfolioArrowIconCover--5eb13";
export var portfolioArrowRightIconCover = "Revolutionizing-module--portfolioArrowRightIconCover--042d1";
export var sheildImgTop = "Revolutionizing-module--sheildImgTop--d4278";
export var shieldTopBannerImg = "Revolutionizing-module--shieldTopBannerImg--00584";
export var skill = "Revolutionizing-module--skill--2b538";
export var slideWrapper = "Revolutionizing-module--slideWrapper--85e68";
export var text = "Revolutionizing-module--text--33a4e";