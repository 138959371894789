// extracted by mini-css-extract-plugin
export var SliderWidth = "TabsMobile-module--SliderWidth--88a30";
export var Trust = "TabsMobile-module--Trust--c2d8e";
export var arrowPoint = "TabsMobile-module--arrowPoint--eda46";
export var buton = "TabsMobile-module--buton--88756";
export var cards = "TabsMobile-module--cards--e5aac";
export var dec = "TabsMobile-module--dec--36513";
export var description = "TabsMobile-module--description--c7dc2";
export var heading = "TabsMobile-module--heading--e4092";
export var image2 = "TabsMobile-module--image2--96304";
export var indDec = "TabsMobile-module--indDec--991de";
export var points = "TabsMobile-module--points--be83f";
export var sheildImgTop = "TabsMobile-module--sheildImgTop--dcd7a";
export var shieldTopBannerImg = "TabsMobile-module--shieldTopBannerImg--85336";
export var skill = "TabsMobile-module--skill--7d65b";
export var text = "TabsMobile-module--text--cfdb0";