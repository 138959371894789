import React from "react"
import * as styles from "./LetsTailor.module.scss"
import Container from "react-bootstrap/Container"
import { Link } from "gatsby"

const LetsTailor = ({ strapiData }) => {
  return (
    <div className={styles.letsTailor}>
      <React.Fragment>
        <Container>
          <div className={styles.headingContainer}>
            <h2
              className={styles.heading}
              dangerouslySetInnerHTML={{
                __html: strapiData?.title,
              }}
            />
            <p
              className={styles.description}
              dangerouslySetInnerHTML={{
                __html: strapiData?.subTitle,
              }}
            />
            <div className={styles.cardFooter}>
              <Link className="newBtn" to={strapiData?.buttons[0]?.url}>
                {strapiData?.buttons[0]?.title}
              </Link>
            </div>
          </div>
        </Container>
      </React.Fragment>
    </div>
  )
}

export default LetsTailor
