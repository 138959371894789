// extracted by mini-css-extract-plugin
export var SliderWidth = "Market-module--SliderWidth--01abd";
export var Trust = "Market-module--Trust--ad587";
export var card = "Market-module--card--99bcd";
export var cardDec = "Market-module--cardDec--6ede1";
export var cardImg = "Market-module--cardImg--ced5f";
export var colGap = "Market-module--colGap--508aa";
export var content = "Market-module--content--c87d7";
export var description = "Market-module--description--2cd0c";
export var heading = "Market-module--heading--04b13";
export var mobileAppDec = "Market-module--mobileAppDec--152a0";