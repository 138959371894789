// extracted by mini-css-extract-plugin
export var card = "Approch-module--card--5c619";
export var cardFooter = "Approch-module--cardFooter--f97f1";
export var cardWrapper = "Approch-module--cardWrapper--76a8f";
export var concerns = "Approch-module--concerns--b581d";
export var concernsHead = "Approch-module--concernsHead--a9fae";
export var customScale = "Approch-module--customScale--021f0";
export var description = "Approch-module--description--b40c4";
export var enterpriseBuildBg = "Approch-module--enterpriseBuildBg--04add";
export var fixed = "Approch-module--fixed--e5e88";
export var headInline = "Approch-module--headInline--fca1e";
export var heading = "Approch-module--heading--bb618";
export var hire = "Approch-module--hire--95e23";
export var hireCyber = "Approch-module--hireCyber--1a5b0";
export var imgCol = "Approch-module--imgCol--423df";
export var leftCol = "Approch-module--leftCol--a4f31";
export var number = "Approch-module--number--0bec6";
export var sqaSolutions = "Approch-module--sqaSolutions--7273d";
export var start = "Approch-module--start--3432c";
export var subHeading = "Approch-module--subHeading--add27";
export var talkBtn = "Approch-module--talkBtn--1bc64";
export var webAppCard = "Approch-module--webAppCard--afeb5";
export var webAppWalking = "Approch-module--webAppWalking--13007";
export var webPageHead = "Approch-module--webPageHead--1bc63";