// extracted by mini-css-extract-plugin
export var arrowAnimation = "FutureProofing-module--arrowAnimation--6c895";
export var black = "FutureProofing-module--black--eaf42";
export var card = "FutureProofing-module--card--7f07c";
export var card1 = "FutureProofing-module--card1--446fb";
export var card2 = "FutureProofing-module--card2--9ca49";
export var card3 = "FutureProofing-module--card3--09193";
export var colGap = "FutureProofing-module--colGap--4db78";
export var colorBlue = "FutureProofing-module--colorBlue--60a34";
export var concerns = "FutureProofing-module--concerns--b60d6";
export var description = "FutureProofing-module--description--ae8c4";
export var heading = "FutureProofing-module--heading--a5a89";
export var izEdge = "FutureProofing-module--izEdge--ce7b7";
export var newBtn = "FutureProofing-module--newBtn--c22ef";
export var progress = "FutureProofing-module--progress--5cf95";
export var subDescription = "FutureProofing-module--subDescription--feb02";
export var subHeading = "FutureProofing-module--subHeading--7eee6";
export var talkBtn = "FutureProofing-module--talkBtn--bcf0b";
export var white = "FutureProofing-module--white--d02da";