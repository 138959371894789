import { Script, graphql } from "gatsby"
import React from "react"
import ContactSales from "../components/common/ContactSales"
import Faqs from "../components/common/Faqs2"
import SEORevamp from "../components/common/SEO_Revamp"
import MainLayout from "../layouts/MainLayout"
import Banner from "../components/software-quality-assurance/Banner"
import Services from "../components/software-quality-assurance/Services"
import SqaServices from "../components/software-quality-assurance/sqaServices"
import Testing from "../components/software-quality-assurance/Testing"
import Approch from "../components/start-up/Approch"
import Seamless from "../components/custom-software/Seamless"
import Trusted from "../components/custom-software/Trusted"
import Success from "../components/enterprise-software/Success"
import Techstack from "../components/home-sections/Techstack"
import TechstackMobile from "../components/home-sections/TechstackMobile"
import ClientLogos from "../components/home-sections/ClientLogos"
import ClientLogosMobile from "../components/home-sections/ClientLogosMobile"
import HorizonMobile from "../components/custom-software/HorizonMobile"

const Qualityassurance = ({ data }) => {
  // console.log(data, "data")
  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 767) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 767)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const heroSection = data?.strapiPage?.sections[0]
  const trustedSerrvices = data?.strapiPage?.sections[1]
  const SQASerrvices = data?.strapiPage?.sections[2]
  const explore = data?.strapiPage?.sections[3]
  const innovators = data?.strapiPage?.sections[5]
  const sqa = data?.strapiPage?.sections[6]
  const trusted = data?.strapiPage?.sections[7]
  const success = data?.strapiPage?.sections[10]
  const faqs = data?.strapiPage?.sections[8]
  // const testimonials = data?.strapiPage?.sections[8]
  const toolsAndTech = data?.strapiPage?.sections[9]
  // console.log(testimonials,'section7')

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout schemas={bodySchema}>
      <Banner strapiData={heroSection} breadCrumb={data?.strapiPage?.buttons} />
      {!isMobile && !isTablet ? <ClientLogos /> : <ClientLogosMobile />}
      {!isMobile && !isTablet ? (
        <Services strapiData={trustedSerrvices} sqaPage={true} />
      ) : (
        <HorizonMobile strapiData={trustedSerrvices} />
      )}

      <Testing strapiData={SQASerrvices} />
      <Approch strapiData={explore} sqaSolution={true} />
      <Seamless strapiData={innovators} sqaService={true} />
      <SqaServices strapiData={sqa} />
      <Trusted strapiData={trusted} sqaTrusted={true} />
      <Success strapiData={success} sqaServices={true} sqaService={true} />
      {!isMobile && !isTablet ? (
        <Techstack strapiData={toolsAndTech} />
      ) : (
        <TechstackMobile strapiData={toolsAndTech} />
      )}
      <Faqs strapiData={faqs} />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query ElixirPage {
    strapiPage(slug: { eq: "sqa-services" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          title2
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
            url
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
    testimonials: strapiPage(slug: { eq: "testimonial" }) {
      sections {
        title
        subTitle
        cards {
          title
          subTitle
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default Qualityassurance

export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })
  return (
    <>
      <Script
        src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
        strategy="post-hydrate"
      />
      <SEORevamp
        title={metaTitle}
        description={metaDescription}
        schemas={headSchema}
        image="https://invozone-backend.s3.amazonaws.com/leading_react_native_app_development_company_e5e33e137c.webp"
      />
    </>
  )
}
