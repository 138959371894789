import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Banner.module.scss"

const Banner = ({ strapiData, custome }) => {
  // const image = getImage(strapiData?.secImages[0]?.localFile)

  return (
    <div className={`${styles.banner} ${custome ? styles.customeBanner : ""}`}>
      <Container>
        <Row>
          <Col lg={7} md={12}>
            <div className={styles.heading}>
              <h1
                className={`${styles.mainTitle} ${
                  custome ? styles.customeHead : ""
                }`}
                dangerouslySetInnerHTML={{ __html: strapiData?.title }}
              ></h1>

              <p
                className={`${styles.bannerHeading} ${
                  custome ? styles.customeBanner : ""
                }`}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.subTitle,
                }}
              >
                {/* Accelerate Your Business with */}
              </p>
              {/* <span className={styles.spanText}>Custom Software Solution</span> */}
              <p
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.description?.description,
                }}
              ></p>
            </div>
            <Link to={"/contact-us/"}>
              <div className={`${styles.blueButton} blueButton`}>
                {strapiData?.buttons[0] && <p>Take The Leap</p>}
              </div>
            </Link>
          </Col>
          <Col lg={5} md={12} className={styles.bnrImg}>
            <div>
              <img
                src={
                  "https://invozone-backend.s3.us-east-1.amazonaws.com/ICON_CUSTOM_PNG_1_2_1_ef1f120216.webp"
                }
              />
            </div>
          </Col>
        </Row>
      </Container>
      <img
        className={styles.curve}
        src="https://invozone-backend.s3.us-east-1.amazonaws.com/line_vector_c415196118.png"
      />
    </div>
  )
}

export default Banner
