// extracted by mini-css-extract-plugin
export var SliderWidth = "WhyChooseOurNLP-module--SliderWidth--26d2e";
export var Trust = "WhyChooseOurNLP-module--Trust--181d2";
export var card = "WhyChooseOurNLP-module--card--41831";
export var cardHeading = "WhyChooseOurNLP-module--cardHeading--86a44";
export var desc = "WhyChooseOurNLP-module--desc--5090a";
export var description = "WhyChooseOurNLP-module--description--a62a5";
export var heading = "WhyChooseOurNLP-module--heading--5124e";
export var headingAndDesc = "WhyChooseOurNLP-module--headingAndDesc--82523";
export var iconContainer = "WhyChooseOurNLP-module--iconContainer--4964f";
export var iconContainerLeft = "WhyChooseOurNLP-module--iconContainerLeft--3a20c";
export var imgContainer = "WhyChooseOurNLP-module--imgContainer--4b2a5";
export var portfolioArrowIcon = "WhyChooseOurNLP-module--portfolioArrowIcon--0f37f";
export var portfolioArrowIconCover = "WhyChooseOurNLP-module--portfolioArrowIconCover--a2c4c";
export var portfolioArrowRightIconCover = "WhyChooseOurNLP-module--portfolioArrowRightIconCover--0d513";
export var sheildImgTop = "WhyChooseOurNLP-module--sheildImgTop--752aa";
export var shieldTopBannerImg = "WhyChooseOurNLP-module--shieldTopBannerImg--9c5a1";