// extracted by mini-css-extract-plugin
export var SliderWidth = "Industries-module--SliderWidth--8766f";
export var Trust = "Industries-module--Trust--60421";
export var arrowPoint = "Industries-module--arrowPoint--3c453";
export var blurInOut = "Industries-module--blurInOut--0b249";
export var buton = "Industries-module--buton--fb93f";
export var cardContent = "Industries-module--cardContent--8c01c";
export var cardFooter = "Industries-module--cardFooter--efa34";
export var cardHover = "Industries-module--cardHover--31acb";
export var cards = "Industries-module--cards--3b593";
export var greyCard = "Industries-module--greyCard--340e5";
export var heading = "Industries-module--heading--12ad5";
export var iconContainer = "Industries-module--iconContainer--4827a";
export var iconContainerLeft = "Industries-module--iconContainerLeft--06535";
export var image2 = "Industries-module--image2--3c869";
export var indDec = "Industries-module--indDec--19cc4";
export var mobileAppBg = "Industries-module--mobileAppBg--a6455";
export var newBtn = "Industries-module--newBtn--0363c";
export var points = "Industries-module--points--a9ee3";
export var portfolioArrowIcon = "Industries-module--portfolioArrowIcon--51332";
export var portfolioArrowIconCover = "Industries-module--portfolioArrowIconCover--3d0cc";
export var portfolioArrowRightIconCover = "Industries-module--portfolioArrowRightIconCover--038b7";
export var redCard = "Industries-module--redCard--b9877";
export var sheildImgTop = "Industries-module--sheildImgTop--fbac6";
export var shieldTopBannerImg = "Industries-module--shieldTopBannerImg--af9c5";
export var skill = "Industries-module--skill--152d1";
export var text = "Industries-module--text--35732";