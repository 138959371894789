// extracted by mini-css-extract-plugin
export var bannerHeading = "Banner-module--bannerHeading--6402a";
export var banner_hero = "Banner-module--banner_hero--e8dde";
export var blueButton = "Banner-module--blueButton--1a445";
export var bnrImg = "Banner-module--bnrImg--28879";
export var curve = "Banner-module--curve--d8210";
export var customeHead = "Banner-module--customeHead--68a6a";
export var description = "Banner-module--description--7fcd4";
export var discoveryBanner = "Banner-module--discoveryBanner--b4d60";
export var heading = "Banner-module--heading--7664d";
export var list = "Banner-module--list--7f808";
export var listItem = "Banner-module--listItem--c70f9";
export var mainTitle = "Banner-module--mainTitle--997da";
export var spanColor = "Banner-module--spanColor--324f1";