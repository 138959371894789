import React from "react"
import * as styles from "./BusinessUsecase.module.scss"
import { Link } from "gatsby"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Container } from "react-bootstrap"

const BusinessUsecase = ({ strapiData }) => {
  return (
    <Container className={styles.sectionContainer}>

      <h2
        className={styles.title}
        dangerouslySetInnerHTML={{
          __html: strapiData?.title,
        }}
      />
      <Row className={styles.cardsContainer}>
        {strapiData?.cards?.map((el, ind) => (
          <Col
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            key={`expertise_${ind}_${el?.title}`}
          >
            <div className={styles.card}>
              <Row>
                <Col xs={6} sm={6} md={6} lg={6}>
                  <div className={styles.cardCounting}>
                    <p>{`0${ind + 1}`}</p>
                  </div>
                </Col>
                <Col
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  className={styles.imgContainer}
                >
                  <img
                    src={el?.image1?.[0]?.localFile?.publicURL}
                    className={styles.quesColorSelected}
                    alt="cardImg"
                  />
                </Col>
              </Row>
              <h3>{el?.title}</h3>
              <p className={styles.description}>{el?.subTitle}</p>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  )
}

export default BusinessUsecase
