import React, { useState } from "react"
import { Accordion, Card, Col, Row } from "react-bootstrap"
import Container from "react-bootstrap/Container"
import "bootstrap/dist/css/bootstrap.min.css"
import "./MobileAppServices.scss"

const MobileAppServices = ({ strapiData }) => {
  const [activeIndex, setActiveIndex] = useState(0)

  return (
    <div className="accordion-with-images">
      <Container>
        <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
        <p className="desc">{strapiData?.subTitle}</p>
        <Row className="pt-5">
          <Col xl={7}>
            <div className="accordion-container">
              <Accordion
                defaultActiveKey="0"
                onSelect={key => setActiveIndex(Number(key))}
              >
                {strapiData?.cards?.map((item, index) => (
                  <div key={index}>
                    <Accordion.Toggle
                      className={`card_header ${
                        activeIndex === index ? "active" : ""
                      }`}
                      eventKey={index.toString()}
                    >
                      <div className="d-flex align-items-center">
                        {activeIndex === index ? (
                          <img
                            style={{ marginRight: 25 }}
                            src={
                              "https://invozone-backend.s3.us-east-1.amazonaws.com/Rectangle_19925_cee0a0d84f.svg"
                            }
                            alt="icon"
                          />
                        ) : (
                          <img
                            style={{ marginRight: 25 }}
                            src={
                              "https://invozone-backend.s3.us-east-1.amazonaws.com/Rectangle_19930_c31acdcf3d.svg"
                            }
                            alt="icon"
                          />
                        )}
                        {item?.title}
                      </div>
                      {activeIndex === index ? (
                        <img
                          src={
                            "https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1707478953_1_fb75eb3bc6.svg"
                          }
                          alt="icon"
                        />
                      ) : (
                        <img
                          src={
                            "https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1707478954_1_b3d5d4e123.svg"
                          }
                          alt="icon"
                        />
                      )}
                    </Accordion.Toggle>
                    <Accordion.Collapse
                      eventKey={index.toString()}
                      in={activeIndex === index}
                    >
                      <Card.Body>
                        <p className="body_text">{item?.subTitle}</p>
                        <Row>
                          {item?.jsonData?.mobile_app_dev?.android?.map(
                            item => {
                              return (
                                <Col
                                  lg={6}
                                  key={index}
                                  className="accordion_custom_body"
                                >
                                  <div className="inner_body_text">
                                    <div>
                                      <img
                                        src={
                                          "https://invozone-backend.s3.us-east-1.amazonaws.com/body_arrow_4f2cc94b09.svg"
                                        }
                                        alt="icon"
                                      />
                                    </div>
                                    <p>{item}</p>
                                  </div>
                                </Col>
                              )
                            }
                          )}
                          {item?.jsonData?.mobile_app_dev?.ios?.map(item => {
                            return (
                              <Col
                                lg={6}
                                key={index}
                                className="accordion_custom_body"
                              >
                                <div className="inner_body_text">
                                  <div>
                                    <img
                                      src={
                                        "https://invozone-backend.s3.us-east-1.amazonaws.com/body_arrow_4f2cc94b09.svg"
                                      }
                                      alt="icon"
                                    />
                                  </div>
                                  <p>{item}</p>
                                </div>
                              </Col>
                            )
                          })}
                          {item?.jsonData?.mobile_app_dev?.cross?.map(item => {
                            return (
                              <Col
                                lg={6}
                                key={index}
                                className="accordion_custom_body"
                              >
                                <div className="inner_body_text">
                                  <div>
                                    <img
                                      src={
                                        "https://invozone-backend.s3.us-east-1.amazonaws.com/body_arrow_4f2cc94b09.svg"
                                      }
                                      alt="icon"
                                    />
                                  </div>
                                  <p>{item}</p>
                                </div>
                              </Col>
                            )
                          })}
                          {item?.jsonData?.mobile_app_dev?.hybrid?.map(item => {
                            return (
                              <Col
                                lg={6}
                                key={index}
                                className="accordion_custom_body"
                              >
                                <div className="inner_body_text">
                                  <div>
                                    <img
                                      src={
                                        "https://invozone-backend.s3.us-east-1.amazonaws.com/body_arrow_4f2cc94b09.svg"
                                      }
                                      alt="icon"
                                    />
                                  </div>
                                  <p>{item}</p>
                                </div>
                              </Col>
                            )
                          })}
                          {item?.jsonData?.mobile_app_dev?.wearable?.map(
                            item => {
                              return (
                                <Col
                                  lg={6}
                                  key={index}
                                  className="accordion_custom_body"
                                >
                                  <div className="inner_body_text">
                                    <div>
                                      <img
                                        src={
                                          "https://invozone-backend.s3.us-east-1.amazonaws.com/body_arrow_4f2cc94b09.svg"
                                        }
                                        alt="icon"
                                      />
                                    </div>
                                    <p>{item}</p>
                                  </div>
                                </Col>
                              )
                            }
                          )}
                        </Row>
                      </Card.Body>
                    </Accordion.Collapse>
                  </div>
                ))}
              </Accordion>
            </div>
          </Col>
          <Col xl={5}>
            <div className="image-container">
              <img
                src={
                  strapiData?.cards[activeIndex]?.image1[0]?.localFile
                    ?.publicURL
                }
                alt="card_avatar"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default MobileAppServices
