// extracted by mini-css-extract-plugin
export var black = "Qickly-module--black--05d30";
export var card = "Qickly-module--card--b9546";
export var card1 = "Qickly-module--card1--725b3";
export var card2 = "Qickly-module--card2--05a2f";
export var card3 = "Qickly-module--card3--07046";
export var colGap = "Qickly-module--colGap--80cbb";
export var description = "Qickly-module--description--4e8e1";
export var heading = "Qickly-module--heading--00a2c";
export var progress = "Qickly-module--progress--d0de0";
export var progressSec = "Qickly-module--progressSec--7a32a";
export var white = "Qickly-module--white--6c23d";