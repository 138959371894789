import React, { useEffect, useRef, useState } from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.scss"
import "slick-carousel/slick/slick.scss"
import * as styles from "./Capabilities.module.scss"
import "../custom-web-application/SnapshotMobile.scss"

function Arrow(props) {
  const { onClick, currentSlide, slideCount, sliderRef } = props
  return (
    <div className={styles.iconContainer}>
      <div
        className={styles.portfolioArrowRightIconCover}
        onClick={() => {
          if (currentSlide === slideCount - 1) {
            sliderRef?.slickGoTo(0) // Reset to first slide
          } else {
            onClick() // Move to next slide
          }
        }}
      >
        <img
          decoding="async"
          loading="lazy"
          alt="arrow"
          src={
            "https://invozone-backend.s3.us-east-1.amazonaws.com/right_f5df90e3bd.svg"
          }
          className={styles.portfolioArrowIcon}
        />
      </div>
    </div>
  )
}

function PrevArrow(props) {
  const { onClick, currentSlide, slideCount, sliderRef } = props
  return (
    <div className={styles.iconContainerLeft}>
      <div
        className={styles.portfolioArrowIconCover}
        onClick={() => {
          if (currentSlide === 0) {
            sliderRef?.slickGoTo(slideCount - 1) // Go to last slide if at the first
          } else {
            onClick() // Move to previous slide
          }
        }}
      >
        <img
          decoding="async"
          loading="lazy"
          alt="arrow"
          src={
            "https://invozone-backend.s3.us-east-1.amazonaws.com/left_ccefba5791.svg"
          }
          className={styles.portfolioArrowIcon}
        />
      </div>
    </div>
  )
}

const Capabilities = ({ strapiData }) => {

  const [current, setCurrent] = useState(0)
  const slide1 = useRef(null)

  useEffect(() => {
    current === strapiData[1]?.cards.length - 1 &&
      setTimeout(() => {
        slide1?.current?.slickGoTo(0)
      }, 5000)
  }, [current])

  const settings = {
    autoplay: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 200,
    infinite: false,
    arrows: true,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setCurrent(next),
    nextArrow: <Arrow />,
    lazyLoad: "ondemand",
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    <React.Fragment>
      <div className={styles.capabilities}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <Row className={`gap-30 ${styles.cardWrapper}`}>
            <Slider {...settings} className={styles.SliderWidth} ref={slide1}>
              {strapiData?.cards?.map((e, i) => (
                <Col xs={12} key={i}>
                  <div className={styles.card}>
                    <div>
                      <img
                        src={e?.image1[0]?.localFile?.publicURL}
                        decoding="async"
                        loading="lazy"
                        alt={e?.image1[0]?.alternativeText ? e?.image1[0]?.alternativeText : ""}
                      />
                    </div>
                    <h3>{e?.title}</h3>
                    <p
                      className="mb-3"
                      dangerouslySetInnerHTML={{
                        __html: e?.subTitle,
                      }}
                    />
                  </div>
                </Col>
              ))}
            </Slider>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Capabilities
