// extracted by mini-css-extract-plugin
export var arrowAnimation = "ComputerVision-module--arrowAnimation--7b270";
export var black = "ComputerVision-module--black--fe4a2";
export var card = "ComputerVision-module--card--5aa63";
export var card1 = "ComputerVision-module--card1--67ff9";
export var card2 = "ComputerVision-module--card2--7316a";
export var card3 = "ComputerVision-module--card3--a016d";
export var colGap = "ComputerVision-module--colGap--b52e5";
export var computerVision = "ComputerVision-module--computerVision--71de2";
export var concerns = "ComputerVision-module--concerns--0d86d";
export var description = "ComputerVision-module--description--0fb08";
export var heading = "ComputerVision-module--heading--fabb2";
export var newBtn = "ComputerVision-module--newBtn--43a0f";
export var progress = "ComputerVision-module--progress--f9544";
export var secondIndex = "ComputerVision-module--secondIndex--56256";
export var subDescription = "ComputerVision-module--subDescription--8f578";
export var subHeading = "ComputerVision-module--subHeading--ad204";
export var talkBtn = "ComputerVision-module--talkBtn--0e5b5";
export var title = "ComputerVision-module--title--ffe42";
export var white = "ComputerVision-module--white--80458";