// extracted by mini-css-extract-plugin
export var arrowAnimation = "Banner-module--arrowAnimation--4fe6e";
export var bannerHeading = "Banner-module--bannerHeading--0d36a";
export var btn = "Banner-module--btn--d99e8";
export var curve = "Banner-module--curve--c2300";
export var dedicated = "Banner-module--dedicated--fe42c";
export var dedicatedBanner = "Banner-module--dedicatedBanner--52916";
export var dedicatedHeading = "Banner-module--dedicatedHeading--87b9f";
export var description = "Banner-module--description--12d39";
export var innerDedicated = "Banner-module--innerDedicated--2aa25";
export var newBtn = "Banner-module--newBtn--5dbf4";
export var subTitle = "Banner-module--subTitle--57ef3";