import { graphql } from "gatsby"
import React from "react"
import Faqs from "../../components/common/Faqs2"
import SEORevamp from "../../components/common/SEO_Revamp"
import Banner from "../../components/infrastructure-code/Banner"
import OverCountries from "../../components/infrastructure-code/OverCountriesProduct"
import MainLayout from "../../layouts/MainLayout"
import DedicatedSoftwareTeam from "../../components/infrastructure-code/DedicatedSoftwareTeam"
import FlexibleEngagement from "../../components/infrastructure-code/FlexibleEngagement"
import SuccessfulProjects from "../../components/infrastructure-code/SuccessfulProjects"
import ModernMainframe from "../../components/infrastructure-code/Mainframe"
import AsCodeService from "../../components/infrastructure-code/AsCodeService"
import IdeaToImpact from "../../components/infrastructure-code/IdeaToImpact"
import FutureProofing from "../../components/infrastructure-code/FutureProofing"
import PartnerSuccess from "../../components/containerization-sections/Partner-Success"

const InfrastructureCode = ({ data }) => {
  console.log("🚀 ~ InfrastructureCode ~ data:", data)
  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 768)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const banner = data?.strapiPage?.sections[0]
  const trust = data?.strapiPage?.sections[1]
  const productDedicatedSoftware = data?.strapiPage?.sections[2]
  const flexible = data?.strapiPage?.sections[3]
  const modernMainframe = data?.strapiPage?.sections[4]
  const ideaToImpact = data?.strapiPage?.sections[7]
  const futureProofing = data?.strapiPage?.sections[8]
  const successfulProjects = data?.strapiPage?.sections[9]
  const faqs = data?.strapiPage?.sections[10]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout bgChanged={false} schemas={bodySchema}>
      <Banner strapiData={banner} />
      <OverCountries strapiData={trust} />
      <DedicatedSoftwareTeam strapiData={productDedicatedSoftware} />
      <FlexibleEngagement strapiData={flexible}/>
      <ModernMainframe strapiData={modernMainframe}/>
      <AsCodeService />
      <IdeaToImpact strapiData={ideaToImpact}/>
      <FutureProofing strapiData={futureProofing}/>
      <SuccessfulProjects strapiData={successfulProjects}/>
      <Faqs strapiData={faqs} /> 
    </MainLayout>
  )
}

export const query = graphql`
  query infrastructureCode {
    strapiPage(slug: { eq: "infrastructure-as-code-services" }) {
      sections {
        title
        cards {
          title
          subTitle
          subTitle2
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          jsonData {
            InfrastructureCode {
              InfraServices
            }
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
  }
`
export default InfrastructureCode

export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })
  return (
    <SEORevamp
      title={metaTitle}
      description={metaDescription}
      schemas={headSchema}
      image="https://invozone-backend.s3.amazonaws.com/software_product_development_services_b1fd581822.svg"
    />
  )
}
