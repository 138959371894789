// extracted by mini-css-extract-plugin
export var bannerHeading = "Banner-module--bannerHeading--fa21a";
export var bnrImg = "Banner-module--bnrImg--f4074";
export var btns = "Banner-module--btns--2c94e";
export var curve = "Banner-module--curve--a64ec";
export var customeHead = "Banner-module--customeHead--4d62b";
export var description = "Banner-module--description--6d816";
export var digitalCommerce = "Banner-module--digitalCommerce--c813f";
export var heading = "Banner-module--heading--5c7da";
export var mainTitle = "Banner-module--mainTitle--d6921";
export var spanColor = "Banner-module--spanColor--967d1";