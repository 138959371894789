// extracted by mini-css-extract-plugin
export var blurInOut = "TopDevelopment-module--blurInOut--2f893";
export var cardContent = "TopDevelopment-module--cardContent--d3b0a";
export var cardFooter = "TopDevelopment-module--cardFooter--aee33";
export var cards = "TopDevelopment-module--cards--afd61";
export var description = "TopDevelopment-module--description--fbdfc";
export var heading = "TopDevelopment-module--heading--5fda9";
export var headingContainer = "TopDevelopment-module--headingContainer--c22bf";
export var image2 = "TopDevelopment-module--image2--13925";
export var letsTailor = "TopDevelopment-module--letsTailor--acd9c";
export var topDevelopment = "TopDevelopment-module--topDevelopment--8841b";