import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Qickly.module.scss"

const cardData = [
  {
    heading: "DevOps Consulting",
    description:
      "Our experts assess your current practices, recommend improvements, and implement tools to streamline your workflow, enhance efficiency, and accelerate time-to-market.",
    textColor: "black",
  },
  {
    heading: "DevSecOps",
    description:
      "We embed security practices from the start, automating testing and monitoring to protect your applications and meet compliance requirements effectively.",
    textColor: "white",
  },
  {
    heading: "Cloud Infrastructure Management & Automation",
    description:
      "We handle your cloud environment's setup, monitoring, and automation, ensuring scalable, reliable operations and reducing manual tasks to improve overall efficiency.",
    button: "Need a Fix",
    textColor: "black",
  },
]

const Qickly = ({ strapiData }) => {
  return (
    <div className={styles.progressSec}>
      <Container>
        <div className={styles.progress}>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          ></h2>

          <Row className="gap-30">
            {cardData.map((item, index) => (
              <Col
                xl={4}
                lg={6}
                md={6}
                sm={12}
                key={index}
                className={`${styles.colGap} d-flex`}
              >
                <div className={`${styles.card} ${styles[`card${index + 1}`]}`}>
                  <p
                    className={`${styles.heading} ${
                      index === 1 ? styles.blue : styles[item.textColor]
                    }`}
                  >
                    {item.heading}
                  </p>
                  <p
                    className={`${styles.description} ${
                      styles[item.textColor]
                    }`}
                  >
                    {item.description}
                  </p>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default Qickly
