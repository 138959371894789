// extracted by mini-css-extract-plugin
export var SliderWidth = "Nitty-Gritty-module--SliderWidth--d7133";
export var card = "Nitty-Gritty-module--card--f6762";
export var cardContainer = "Nitty-Gritty-module--cardContainer--0aa34";
export var cardWrapper = "Nitty-Gritty-module--cardWrapper--e0c36";
export var description = "Nitty-Gritty-module--description--65f60";
export var fineBg = "Nitty-Gritty-module--fineBg--f8f27";
export var heading = "Nitty-Gritty-module--heading--7c144";
export var headingPremium = "Nitty-Gritty-module--headingPremium--3f67c";
export var iconContainer = "Nitty-Gritty-module--iconContainer--7535f";
export var iconContainerLeft = "Nitty-Gritty-module--iconContainerLeft--f7d9f";
export var nittyGritty = "Nitty-Gritty-module--nittyGritty--6a28f";
export var portfolioArrowIcon = "Nitty-Gritty-module--portfolioArrowIcon--4569a";
export var portfolioArrowIconCover = "Nitty-Gritty-module--portfolioArrowIconCover--2a1b6";
export var portfolioArrowRightIconCover = "Nitty-Gritty-module--portfolioArrowRightIconCover--a9355";
export var premiumImg = "Nitty-Gritty-module--premiumImg--d4373";
export var teamButton = "Nitty-Gritty-module--teamButton--9912b";