// extracted by mini-css-extract-plugin
export var arrowAnimation = "Banner-module--arrowAnimation--8693a";
export var backgroundImg = "Banner-module--backgroundImg--3d87f";
export var bannerCurve = "Banner-module--bannerCurve--10613";
export var bannerHeading = "Banner-module--bannerHeading--5c1ed";
export var btn = "Banner-module--btn--08e7c";
export var btne = "Banner-module--btne--0cbf2";
export var curve = "Banner-module--curve--68fc8";
export var dedicated = "Banner-module--dedicated--4b9ae";
export var dedicatedBanner = "Banner-module--dedicatedBanner--6a9d3";
export var dedicatedHeadingStatic = "Banner-module--dedicatedHeadingStatic--a1bf5";
export var description = "Banner-module--description--6e499";
export var innerDedicated = "Banner-module--innerDedicated--b1f82";
export var newBtn = "Banner-module--newBtn--f8c4e";
export var sliderButtons = "Banner-module--sliderButtons--4e4fe";
export var subTitle = "Banner-module--subTitle--9491d";