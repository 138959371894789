import React, { useRef, useEffect, useState } from "react"
import Container from "react-bootstrap/Container"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import * as styles from "./BenefitsOfDevTools.module.scss"

const DevOpsBenefits = ({ strapiData }) => {
  const image = getImage(strapiData?.secImages && strapiData?.secImages[0]?.localFile)

  const cardRefs = strapiData?.cards?.map(() => useRef())
  const [zIndexValues, setZIndexValues] = useState([])

  useEffect(() => {
    const handleScroll = () => {
      const newZIndexValues = cardRefs.map(cardRef => {
        if (cardRef.current) {
          const rect = cardRef.current.getBoundingClientRect()
          const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight

          if (isVisible) {
            return 1
          } else {
            return 0
          }
        }
        return 0
      })

      setZIndexValues(newZIndexValues)
    }

    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <div className={styles.hireCyber}>
      <Container>
        <div>
          <Row className={styles.start}>
            <Col
              lg={6}
              md={12}
              xs={12}
              sm={12}
              className={`text-center ${styles.leftCol}`}
            >
              <div>
                {image ? (
                  <GatsbyImage
                    placeholder="blurred"
                    decoding="async"
                    loading="lazy"
                    image={image}
                    alt={strapiData?.title}
                  />
                ) : (
                  <img
                    src={strapiData?.secImages[0]?.localFile?.publicURL}
                    alt={"benefits_of_devops_services_provider"}
                    decoding="async"
                    loading="lazy"
                  />
                )}
              </div>
              <div className={styles.hire}>
                <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
                <div
                  className={styles.description}
                  dangerouslySetInnerHTML={{
                    __html: strapiData?.subTitle,
                  }}
                />
              </div>
            </Col>
            <Col
              lg={6}
              md={12}
              xs={12}
              sm={12}
              className={`${styles.cardWrapper} gap-30`}
            >
              {strapiData?.cards?.map((e, i) => (
                <div
                  key={i}
                  className={` ${styles.card} ${styles.stickyCard}`}
                  ref={cardRefs[i]}
                  style={{ zIndex: zIndexValues[i] }}
                >
                  <img
                    src={e?.image1 && e?.image1[0]?.localFile?.publicURL}
                    alt={e?.title}
                    decoding="async"
                    loading="lazy"
                  />
                  <h3>{e?.title}</h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: e?.subTitle,
                    }}
                  />
                </div>
              ))}
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default DevOpsBenefits
