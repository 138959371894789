// extracted by mini-css-extract-plugin
export var arrowAnimation = "Banner-module--arrowAnimation--c11a5";
export var banner = "Banner-module--banner--769c8";
export var bannerHeading = "Banner-module--bannerHeading--c2829";
export var baseBtn = "Banner-module--baseBtn--59694";
export var bnrImg = "Banner-module--bnrImg--2c38d";
export var btnsContainer = "Banner-module--btnsContainer--13fcc";
export var curve = "Banner-module--curve--c2f64";
export var customeHead = "Banner-module--customeHead--37e0b";
export var darkBtn = "Banner-module--darkBtn--b88db";
export var description = "Banner-module--description--a8da0";
export var heading = "Banner-module--heading--07ca6";
export var mainTitle = "Banner-module--mainTitle--3ace7";
export var spanColor = "Banner-module--spanColor--0a3c6";