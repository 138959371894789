import React from "react"
import { Card, Button } from "react-bootstrap"
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import "./Testing.scss"
import { Link } from "gatsby"

const TestingServices = ({ strapiData }) => {
  return (
    <div className="testing-services-container">
      <Container>
        <h2
          className=" mb-3"
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <p
          className="desc"
          dangerouslySetInnerHTML={{ __html: strapiData?.subTitle }}
        />
        <Row>
          {strapiData?.cards?.map((service, index) => (
            <Col key={index} md={6} lg={4} xl={3} className="mb-4">
              <div
                className={`service-card ${
                  index === 4 ? "highlight" : ""
                } h-100`}
              >
                <Card.Body>
                  <div className="mb-3">
                    <img
                      className="icons"
                      src={service?.image1?.[0]?.localFile?.publicURL}
                      alt=""
                    />
                  </div>
                  <Card.Title>{service.title}</Card.Title>
                  <Card.Text className="cardDec">{service.subTitle}</Card.Text>
                </Card.Body>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default TestingServices
