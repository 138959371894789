// extracted by mini-css-extract-plugin
export var arrowAnimation = "Banner-module--arrowAnimation--44a80";
export var backgroundImg = "Banner-module--backgroundImg--42e66";
export var bannerCurve = "Banner-module--bannerCurve--349ae";
export var bannerHeading = "Banner-module--bannerHeading--7bb7d";
export var btn = "Banner-module--btn--14211";
export var btne = "Banner-module--btne--c6628";
export var curve = "Banner-module--curve--d728c";
export var dedicated = "Banner-module--dedicated--e0342";
export var dedicatedBanner = "Banner-module--dedicatedBanner--63257";
export var dedicatedHeadingStatic = "Banner-module--dedicatedHeadingStatic--d0808";
export var description = "Banner-module--description--be1b6";
export var innerDedicated = "Banner-module--innerDedicated--48263";
export var newBtn = "Banner-module--newBtn--cfff5";
export var sliderButtons = "Banner-module--sliderButtons--57082";
export var subTitle = "Banner-module--subTitle--cab92";