// extracted by mini-css-extract-plugin
export var arrowAnimation = "ITHealth-module--arrowAnimation--e35e6";
export var black = "ITHealth-module--black--0c62d";
export var card = "ITHealth-module--card--59aa8";
export var card1 = "ITHealth-module--card1--211f3";
export var card2 = "ITHealth-module--card2--5291b";
export var card3 = "ITHealth-module--card3--66a42";
export var colGap = "ITHealth-module--colGap--6e054";
export var concerns = "ITHealth-module--concerns--30af2";
export var description = "ITHealth-module--description--fccf7";
export var heading = "ITHealth-module--heading--c5785";
export var izValue = "ITHealth-module--izValue--8dd2b";
export var newBtn = "ITHealth-module--newBtn--3adb8";
export var progress = "ITHealth-module--progress--8d1e2";
export var secondIndex = "ITHealth-module--secondIndex--02075";
export var subDescription = "ITHealth-module--subDescription--7d0a5";
export var subHeading = "ITHealth-module--subHeading--1102c";
export var talkBtn = "ITHealth-module--talkBtn--a4026";
export var white = "ITHealth-module--white--fdf59";