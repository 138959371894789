import { Script, graphql } from "gatsby"
import React from "react"
// import ContactSales from "../../components/common/ContactSales"
import Faqs from "../components/common/Faqs2"
import SEORevamp from "../components/common/SEO_Revamp"
import Banner from "../components/customer-experience/Banner"
import ClientLogos from "../components/home-sections/ClientLogos"
import ClientLogosMobile from "../components/home-sections/ClientLogosMobile"
import MainLayout from "../layouts/MainLayout"
import ContactSales from "../components/common/ContactSales"
import Capabilities from "../components/containerization-sections/Capabilities"
import PartnerSuccess from "../components/containerization-sections/Partner-Success"
// import DedicatedSoftware from "../components/customer-experience/DedicatedSoftware"
import EngagementModal from "../components/customer-experience/Engagement-Modal"
import GetStarted from "../components/customer-experience/Get-started"
import SuccessStories from "../components/containerization-sections/success-stories"
import Tabs from "../components/customer-experience/Tabs"
import TabsMobile from "../components/customer-experience/TabsMobile"

export const Head = ({ data }) => {
  console.log("🚀 ~ Head ~ data:", data)
  const { metaTitle } = data?.strapiPage?.seo
  const { metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })
  return (
    <>
      <Script
        src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
        strategy="post-hydrate"
      />

      <SEORevamp
        title={metaTitle}
        description={metaDescription}
        // schemas={headSchema}
        image="https://invozone-backend.s3.amazonaws.com/hire_elixir_developers_f701d7b38c.webp"
      />
    </>
  )
}
const CustomerExperience = ({ data }) => {
  console.log("cx-optimization-services", data)
  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 767)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const heroSection = data?.strapiPage?.sections[0]
  const dedicatedSoftware = data?.strapiPage?.sections[2]
  const chooseIZ = data?.strapiPage?.sections[3]
  const engagement = data?.strapiPage?.sections[4]
  const stories = data?.strapiPage?.sections[5]
  const cxOptimization = data?.strapiPage?.sections[6]
  const getStarted = data?.strapiPage?.sections[7]
  const faqs = data?.strapiPage?.sections[8]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })
  return (
    <MainLayout schemas={bodySchema}>
      <Banner strapiData={heroSection} />
      <div className="client-logos-container">
        {!isMobile && !isTablet ? <ClientLogos /> : <ClientLogosMobile />}
      </div>
      {!isMobile && !isTablet ? (
        <Tabs strapiData={dedicatedSoftware} />
      ) : (
        <TabsMobile strapiData={dedicatedSoftware} />
      )}
      <Capabilities strapiData={chooseIZ} />
      <EngagementModal strapiData={engagement} />
      <PartnerSuccess strapiData={stories} />
      <SuccessStories strapiData={cxOptimization} />
      <GetStarted strapiData={getStarted} />
      <Faqs strapiData={faqs} pageName="customer-experience" />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query OptimizationServices {
    strapiPage(slug: { eq: "cx-optimization-services" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          title2
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
          jsonData {
            discovery_phases {
              icon
              title
              steps {
                icon
                text
              }
            }
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
    testimonials: strapiPage(slug: { eq: "testimonial" }) {
      sections {
        title
        subTitle
        cards {
          title
          subTitle
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default CustomerExperience
