import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./ComputerVision.module.scss"
import staric from '../../images/ai-development-page/staric.svg'

const cardData = [
  {
    heading: "I'm A",
    subHeading: "Startup.",
    description:
      "Remarkable ideas often stall without the right team to bring them to life",
    textColor: "white",
    button: "Get Started",
    link: "/software-development-services/startup/",
  },
  {
    heading: "I'm An",
    subHeading: "Enterprise.",
    description:
      "Scaling without the right infrastructure and expertise can lead to costly bottlenecks.",
    textColor: "black",
    button: "Explore More",
    link: "/software-development-services/enterprise/",
  },
  {
    heading: "I Need A",
    subHeading: "Rescue.",
    description:
      "A messy codebase and tech debt are suffocating your progress.",
    button: "Need a Fix",
    textColor: "white",
    link: "/software-development-services/rescue/",
  },
]

const ComputerVision = ({ strapiData }) => {
  return (
    <div className={styles.computerVision}>
      <Container>
        <div className={styles.progress}>
          <h2
            className={styles.title}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <p
            className={styles.subDescription}
            dangerouslySetInnerHTML={{ __html: strapiData?.subTitle }}
          />
          <Row className="gap-30">
            {strapiData.cards.map((item, index) => (
              <Col
                xl={4}
                lg={6}
                md={6}
                sm={12}
                key={index}
                className={`${styles.colGap} d-flex`}
              >
                <div className={`${styles.card} ${styles[`card${index + 1}`]}`}>
                  <p
                    className={`${styles.heading} ${
                      index === 1 ? styles.secondIndex : ""
                    }`}
                    dangerouslySetInnerHTML={{
                      __html: item?.title,
                    }}
                  />
                  <p
                    className={`${styles.description} ${
                      styles[item.textColor]
                    }`}
                  >
                    {item.subTitle}
                  </p>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default ComputerVision
