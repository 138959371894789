import React from 'react';
import * as styles from './AILaunchpad.module.scss';
import { Link } from 'gatsby';
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Container } from 'react-bootstrap';
import staric from '../../images/ai-development-page/staric.svg'
import arrowRight from '../../images/ai-development-page/arrowRight.svg'


const AILaunchpad = ({ strapiData }) => {
    return (
        <Container className={styles.sectionContainer}>
            <Row style={{margin: 0 }}>
                <Col xl={4} lg={12} md={12} sm={12} xs={12}>
                    <h2
                        className={styles.heading}
                        dangerouslySetInnerHTML={{
                            __html: strapiData?.title,
                        }}
                    />
                </Col>

                <Col xl={5} lg={6} md={12} sm={12} xs={12}>
                    <p
                        className={styles.description}
                        dangerouslySetInnerHTML={{
                            __html: strapiData?.subTitle,
                        }}
                    />
                </Col>
                <Col xl={3} lg={6} md={12} sm={12} xs={12} className={styles.cta}>
                    <Link to={strapiData?.buttons[0]?.url} style={{textDecoration: 'none'}}>
                        <div className={`blueButton`}>
                            {strapiData?.buttons?.[0] && (
                                <p>{strapiData?.buttons[0]?.title}</p>
                            )}
                        </div>
                    </Link>
                </Col>
            </Row>

            <div className={styles.whatIzLaunchPad}>
                <img src={staric} alt='star' />
                <p className={styles.stripText}>{strapiData?.subTitle2}</p>
                <img src={staric} alt='star' />
            </div>

            <Row className={styles.keyPoints}>
                {
                    strapiData?.cards?.map(el => <Col xs={12} sm={12} md={6} key={el}>
                        <div className={styles.keyPoint}>
                            <img src={arrowRight} alt={'arrow'} />
                            <p>{el?.title}</p>
                        </div>
                    </Col>)

                }


            </Row>
        </Container>
    )
}

export default AILaunchpad;