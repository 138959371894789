// extracted by mini-css-extract-plugin
export var arrowAnimation = "ITHealth-module--arrowAnimation--bd7a0";
export var black = "ITHealth-module--black--ecb39";
export var card = "ITHealth-module--card--6824f";
export var card1 = "ITHealth-module--card1--c784f";
export var card2 = "ITHealth-module--card2--464ca";
export var card3 = "ITHealth-module--card3--8f832";
export var colGap = "ITHealth-module--colGap--732fa";
export var concerns = "ITHealth-module--concerns--4853b";
export var description = "ITHealth-module--description--df76d";
export var heading = "ITHealth-module--heading--9ff64";
export var izValue = "ITHealth-module--izValue--0f670";
export var newBtn = "ITHealth-module--newBtn--d6abb";
export var progress = "ITHealth-module--progress--1f277";
export var secondIndex = "ITHealth-module--secondIndex--d9944";
export var subDescription = "ITHealth-module--subDescription--93375";
export var subHeading = "ITHealth-module--subHeading--3b27a";
export var talkBtn = "ITHealth-module--talkBtn--9d2ad";
export var title = "ITHealth-module--title--7df7a";
export var white = "ITHealth-module--white--0db92";