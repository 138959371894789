// extracted by mini-css-extract-plugin
export var SliderWidth = "WordsFromClients-module--SliderWidth--20091";
export var Trust = "WordsFromClients-module--Trust--48fe7";
export var blueText = "WordsFromClients-module--blueText--c4439";
export var clientContent = "WordsFromClients-module--clientContent--256a0";
export var coma = "WordsFromClients-module--coma--b6761";
export var content = "WordsFromClients-module--content--5eba1";
export var customModal = "WordsFromClients-module--customModal--e6635";
export var dec = "WordsFromClients-module--dec--13d22";
export var description = "WordsFromClients-module--description--3ce1f";
export var designation = "WordsFromClients-module--designation--d72f7";
export var dot = "WordsFromClients-module--dot--b3539";
export var heading = "WordsFromClients-module--heading--3c593";
export var headingContainer = "WordsFromClients-module--headingContainer--21c2f";
export var iconContainer = "WordsFromClients-module--iconContainer--a35df";
export var iconContainerLeft = "WordsFromClients-module--iconContainerLeft--3c5bf";
export var logos = "WordsFromClients-module--logos--1862e";
export var logosBg = "WordsFromClients-module--logosBg--63fe2";
export var name = "WordsFromClients-module--name--079fc";
export var playBtn = "WordsFromClients-module--playBtn--71a34";
export var portfolioArrowIcon = "WordsFromClients-module--portfolioArrowIcon--3cca6";
export var portfolioArrowIconCover = "WordsFromClients-module--portfolioArrowIconCover--9c934";
export var portfolioArrowRightIconCover = "WordsFromClients-module--portfolioArrowRightIconCover--3f683";
export var view = "WordsFromClients-module--view--2df69";