// extracted by mini-css-extract-plugin
export var card = "AIConsultingServices-module--card--6b93a";
export var cardCounting = "AIConsultingServices-module--cardCounting--c7b9e";
export var cardHeading = "AIConsultingServices-module--cardHeading--76034";
export var cardsContainer = "AIConsultingServices-module--cardsContainer--689a0";
export var cta = "AIConsultingServices-module--cta--52410";
export var description = "AIConsultingServices-module--description--2c4c1";
export var heading = "AIConsultingServices-module--heading--88335";
export var highlight = "AIConsultingServices-module--highlight--53882";
export var imgContainer = "AIConsultingServices-module--imgContainer--2b0c8";
export var sectionContainer = "AIConsultingServices-module--sectionContainer--2db79";