// extracted by mini-css-extract-plugin
export var aiPoweredCard = "AIPowered-module--aiPoweredCard--0f75c";
export var cardContent = "AIPowered-module--cardContent--f4097";
export var cardDescription = "AIPowered-module--cardDescription--416c0";
export var cardHeading = "AIPowered-module--cardHeading--d2641";
export var counting = "AIPowered-module--counting--9027b";
export var desc = "AIPowered-module--desc--88b6c";
export var headingContainer = "AIPowered-module--headingContainer--983c6";
export var keyPoint = "AIPowered-module--keyPoint--8f8fb";
export var keyPoints = "AIPowered-module--keyPoints--68908";
export var sectionContainer = "AIPowered-module--sectionContainer--8e2c1";
export var subTitle = "AIPowered-module--subTitle--abccf";
export var title = "AIPowered-module--title--ccbee";