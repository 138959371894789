// extracted by mini-css-extract-plugin
export var SliderWidth = "Capabilities-module--SliderWidth--51fad";
export var capabilities = "Capabilities-module--capabilities--7114b";
export var card = "Capabilities-module--card--e0b80";
export var cardWrapper = "Capabilities-module--cardWrapper--f6fb5";
export var description = "Capabilities-module--description--e30f9";
export var fineBg = "Capabilities-module--fineBg--d3851";
export var heading = "Capabilities-module--heading--e7801";
export var headingPremium = "Capabilities-module--headingPremium--2ef5f";
export var iconContainer = "Capabilities-module--iconContainer--534cb";
export var iconContainerLeft = "Capabilities-module--iconContainerLeft--521af";
export var portfolioArrowIcon = "Capabilities-module--portfolioArrowIcon--68e34";
export var portfolioArrowIconCover = "Capabilities-module--portfolioArrowIconCover--0fdd5";
export var portfolioArrowRightIconCover = "Capabilities-module--portfolioArrowRightIconCover--942cf";
export var premiumImg = "Capabilities-module--premiumImg--9827f";
export var teamButton = "Capabilities-module--teamButton--5c9ea";