// extracted by mini-css-extract-plugin
export var SliderWidth = "Engagement-Modal-module--SliderWidth--e8eb5";
export var arrowImg = "Engagement-Modal-module--arrowImg--2c9f1";
export var card = "Engagement-Modal-module--card--9b2ed";
export var cardImg = "Engagement-Modal-module--cardImg--f7704";
export var cardWrapper = "Engagement-Modal-module--cardWrapper--7ca32";
export var card_title = "Engagement-Modal-module--card_title--6cae0";
export var cards = "Engagement-Modal-module--cards--ffca4";
export var cmsWebIconCOntainer = "Engagement-Modal-module--cmsWebIconCOntainer--439fa";
export var cmsWebIconCOntainerLeft = "Engagement-Modal-module--cmsWebIconCOntainerLeft--5a72e";
export var description = "Engagement-Modal-module--description--af740";
export var engagementModalCardMain = "Engagement-Modal-module--engagementModalCardMain--258a7";
export var heading = "Engagement-Modal-module--heading--0bdbb";
export var headingPremium = "Engagement-Modal-module--headingPremium--f9eab";
export var itengagementModal = "Engagement-Modal-module--itengagementModal--9972c";
export var portfolioArrowIcon = "Engagement-Modal-module--portfolioArrowIcon--9c5fc";
export var portfolioArrowIconCover = "Engagement-Modal-module--portfolioArrowIconCover--a5dde";
export var portfolioArrowRightIconCover = "Engagement-Modal-module--portfolioArrowRightIconCover--8100d";
export var premiumImg = "Engagement-Modal-module--premiumImg--58a69";
export var providingImg = "Engagement-Modal-module--providingImg--9a4b5";
export var teamButton = "Engagement-Modal-module--teamButton--2f713";
export var trailBg = "Engagement-Modal-module--trailBg--974a5";