// extracted by mini-css-extract-plugin
export var SliderWidth = "PeakEfficiency-module--SliderWidth--6a56a";
export var card = "PeakEfficiency-module--card--f43f8";
export var cardCol = "PeakEfficiency-module--cardCol--50e9d";
export var cardWrapper = "PeakEfficiency-module--cardWrapper--2a050";
export var description = "PeakEfficiency-module--description--8742a";
export var fineBg = "PeakEfficiency-module--fineBg--59066";
export var firstChild = "PeakEfficiency-module--firstChild--5c6e9";
export var heading = "PeakEfficiency-module--heading--3ca90";
export var headingPremium = "PeakEfficiency-module--headingPremium--eaee2";
export var iconContainer = "PeakEfficiency-module--iconContainer--50ef0";
export var iconContainerLeft = "PeakEfficiency-module--iconContainerLeft--83290";
export var imageContainer = "PeakEfficiency-module--imageContainer--b5054";
export var portfolioArrowIcon = "PeakEfficiency-module--portfolioArrowIcon--7eb68";
export var portfolioArrowIconCover = "PeakEfficiency-module--portfolioArrowIconCover--0a5f9";
export var portfolioArrowRightIconCover = "PeakEfficiency-module--portfolioArrowRightIconCover--864cf";
export var premiumImg = "PeakEfficiency-module--premiumImg--92d80";
export var refineDevOps = "PeakEfficiency-module--refineDevOps--892a1";
export var teamButton = "PeakEfficiency-module--teamButton--30c48";