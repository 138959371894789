import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./FlexibleEngagement.module.scss"

const FlexibleEngagement = ({ strapiData }) => {
  return (
    <div className={styles.progressSec}>
      <Container>
        <div className={styles.progress}>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{
              __html: strapiData?.title,
            }}
          />

          <p className={styles.subTitle}>
            We offer flexible engagement models designed to meet the specific
            needs of your customer experience optimization projects, ensuring
            the best approach for your business.
          </p>

          <Row className="justify-content-center">
            {strapiData?.cards?.map((item, index) => (
              <Col
                xl={3}
                lg={12}
                key={index}
                className={`${styles.colGap} d-flex`}
              >
                <div
                  className={`${styles.card} 
                   
                  cardHoverEffect`}
                >
                  <img src={item?.image1[0]?.localFile?.publicURL} alt="tick" />
                  <h3 className={styles.subHeading}>{item.title}</h3>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default FlexibleEngagement
