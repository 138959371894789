import React, { useEffect, useRef, useState } from "react"
import * as styles from "./Seamless.module.scss"
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Slider from "react-slick"

function Arrow(props, webScope, sqaService) {
  const { onClick, currentSlide, slideCount, sliderRef } = props
  return (
    <div
      className={`${styles.iconContainer} ${
        webScope ? styles.webArrowRight : ""
      } ${sqaService ? styles.sqaArrowRight : ""}`}
    >
      <div
        className={styles.portfolioArrowRightIconCover}
        onClick={() => {
          if (currentSlide === slideCount - 1) {
            sliderRef?.slickGoTo(0)
          } else {
            onClick()
          }
        }}
      >
        <img
          decoding="async"
          loading="lazy"
          alt="arrow"
          src={
            "https://invozone-backend.s3.us-east-1.amazonaws.com/right_f5df90e3bd.svg"
          }
          className={styles.portfolioArrowIcon}
        />
      </div>
    </div>
  )
}

function PrevArrow(props, webScope, sqaService) {
  const { onClick, currentSlide, slideCount, sliderRef } = props
  return (
    <div
      className={`${styles.iconContainerLeft} ${
        webScope ? styles.webArrowLeft : ""
      } ${sqaService ? styles.sqaArrowLeft : ""}`}
    >
      <div
        className={styles.portfolioArrowIconCover}
        onClick={() => {
          if (currentSlide === 0) {
            sliderRef?.slickGoTo(slideCount - 1)
          } else {
            onClick()
          }
        }}
      >
        <img
          decoding="async"
          loading="lazy"
          alt="arrow"
          src={
            "https://invozone-backend.s3.us-east-1.amazonaws.com/left_ccefba5791.svg"
          }
          className={styles.portfolioArrowIcon}
        />
      </div>
    </div>
  )
}

const Seamless = ({ strapiData, devOps, webScope, sqaService }) => {
  const [showModal, setShowModal] = useState(false)
  const [currentVideo, setCurrentVideo] = useState("")
  const sliderRef = useRef(null) // Updated slider reference
  const [currentSlide, setCurrentSlide] = useState(0)

  const slide1 = useRef(null)
  const [current, setCurrent] = useState(0)
  const [current_1, setCurrent_1] = useState(0)
  const [current_2, setCurrent_2] = useState(0)

  useEffect(() => {
    ;(current === strapiData?.cards.length - 3 ||
      current_1 === strapiData?.cards.length - 2 ||
      current_2 === strapiData?.cards.length - 1) &&
      setTimeout(() => {
        slide1?.current?.slickGoTo(0)
      }, 5000)
  }, [current, current_1, current_2])

  const settings = {
    autoplay: true,
    autoplaySpeed: 3000,
    dots: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    beforeChange: (current, next) => setCurrent(next),
    prevArrow: (
      <PrevArrow
        currentSlide={currentSlide}
        slideCount={strapiData?.cards?.length || 0}
        sliderRef={slide1.current}
      />
    ),
    nextArrow: (
      <Arrow
        currentSlide={currentSlide}
        slideCount={strapiData?.cards?.length || 0}
        sliderRef={slide1.current}
      />
    ),
    infinite: false,
    speed: 500,
    afterChange: current => {
      setCurrentSlide(current)
    },
    responsive: [
      {
        breakpoint: 1140,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  const handleVideoPlay = videoUrl => {
    setCurrentVideo(videoUrl)
    setShowModal(true)
  }

  return (
    <div
      className={`${styles.Trust} ${devOps ? styles.devOpsTrust : ""} ${
        webScope ? styles.webScopeBg : ""
      }`}
    >
      <Container>
        <h2
          className={`${styles.heading} ${devOps ? styles.devOpsHead : ""} ${
            webScope ? styles.webHeading : ""
          } ${sqaService ? styles.sqaPageHead : ""}`}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <p
          className={`${styles.description} ${devOps ? styles.devOpsDec : ""} ${
            webScope ? styles.descriptionMargin : ""
          }`}
          dangerouslySetInnerHTML={{ __html: strapiData?.subTitle }}
        />

        <Row className={`gap-30 ${styles.cardWrapper}`}>
          <Slider {...settings} className={styles.SliderWidth} ref={slide1}>
            {strapiData?.cards &&
              strapiData?.cards.map((e, i) => (
                <Col xs={12} key={i} className="d-flex">
                  <div
                    className={`${styles.card} ${
                      sqaService ? styles.sqaServiceCard : ""
                    }`}
                  >
                    <img
                      src={e?.image1[0]?.localFile?.publicURL}
                      alt={e?.title}
                      decoding="async"
                      loading="lazy"
                    />
                    <h3>{e?.title}</h3>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: e?.subTitle,
                      }}
                      className={styles.cardDec}
                    />
                  </div>
                </Col>
              ))}
          </Slider>
        </Row>
      </Container>
    </div>
  )
}

export default Seamless
