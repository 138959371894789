import React from "react"
import * as styles from "./TopDevelopment.module.scss"
import Container from "react-bootstrap/Container"
import { Link } from "gatsby"

const TopDevelopment = ({ strapiData }) => {
  return (
    <div className={styles.topDevelopment}>
      <React.Fragment>
        <Container>
          <div className={styles.headingContainer}>
            <h2
              className={styles.heading}
              dangerouslySetInnerHTML={{
                __html: strapiData?.title,
              }}
            />
            <div>
              <p
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.subTitle,
                }}
              />
              <div className={styles.cardFooter}>
                <Link className="newBtn" to={strapiData?.buttons[0]?.url}>
                  {strapiData?.buttons[0]?.title}
                </Link>
                <Link className="newBtn" to={strapiData?.buttons[1]?.url}>
                  {strapiData?.buttons[1]?.title}
                </Link>
              </div>
            </div>
          </div>
        </Container>
      </React.Fragment>
    </div>
  )
}

export default TopDevelopment
