// extracted by mini-css-extract-plugin
export var arrowAnimation = "FlexibleEngagement-module--arrowAnimation--affcc";
export var blackButton = "FlexibleEngagement-module--blackButton--236f3";
export var blueButton = "FlexibleEngagement-module--blueButton--59537";
export var card = "FlexibleEngagement-module--card--ffd1f";
export var colGap = "FlexibleEngagement-module--colGap--0ef28";
export var description = "FlexibleEngagement-module--description--9b587";
export var heading = "FlexibleEngagement-module--heading--bba6d";
export var newBtn = "FlexibleEngagement-module--newBtn--b8d7b";
export var newBtn2 = "FlexibleEngagement-module--newBtn2--d3fbb";
export var progress = "FlexibleEngagement-module--progress--911d5";
export var progressSec = "FlexibleEngagement-module--progressSec--aac2b";
export var subHeading = "FlexibleEngagement-module--subHeading--193c4";
export var subTitle = "FlexibleEngagement-module--subTitle--a603d";