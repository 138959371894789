// extracted by mini-css-extract-plugin
export var arrowAnimation = "Banner-module--arrowAnimation--9cb5a";
export var banner = "Banner-module--banner--b55b1";
export var bannerHeading = "Banner-module--bannerHeading--cb612";
export var baseBtn = "Banner-module--baseBtn--2e0eb";
export var bnrImg = "Banner-module--bnrImg--7459f";
export var btnsContainer = "Banner-module--btnsContainer--f688c";
export var curve = "Banner-module--curve--b2796";
export var customeHead = "Banner-module--customeHead--5c6af";
export var darkBtn = "Banner-module--darkBtn--d6e29";
export var description = "Banner-module--description--57e42";
export var heading = "Banner-module--heading--6c475";
export var mainTitle = "Banner-module--mainTitle--38b82";
export var spanColor = "Banner-module--spanColor--1721b";