import React, { useState } from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./OurApproach.module.scss"
// import staric from '../../images/ai-development-page/staric.svg'
import blueStaric from "../../images/ai-development-page/blueStaric.svg"

const OurApproach = ({ strapiData }) => {
  return (
    <div className={styles.sectionContainer}>
      <Container>
        <div>
          <h2
            className={styles.subTitle}
            dangerouslySetInnerHTML={{
              __html: strapiData?.title,
            }}
          />
          <p
            className={styles.desc}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
        </div>
      </Container>
      <div className={styles.aiPoweredCard}>
        {strapiData?.cards?.map(el => (
          <div className={styles.cardContent} key={`approach_${el?.title}`}>
            {" "}
            <Container>
              <Row className={styles.innerRow}>
                <Col xs={6} sm={6} md={3} lg={1}>
                  <img
                    src={el?.image1?.[0]?.localFile?.publicURL}
                    alt={"make smarter"}
                  />
                </Col>
                <Col xs={6} sm={6} md={6} lg={4}>
                  <h4>
                    <span>{el?.title}</span>
                    <br />
                  </h4>
                </Col>
                <Col xs={6} sm={6} md={3} lg={1}>
                  {/* <img src={staric} alt={'make smarter'} className={styles.whiteStar} /> */}
                  <img src={blueStaric} alt={"make smarter"} />
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <p className={styles.cardDescription}>{el?.subTitle}</p>
                </Col>
              </Row>
            </Container>{" "}
          </div>
        ))}
      </div>
    </div>
  )
}

export default OurApproach
